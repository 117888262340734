import { Box, Typography, Card, Button, Chip, Stack } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";
import axios from "axios";
import jwt from "jwt-decode";
import { makeStyles } from "@mui/styles";
import Iconify from "../../components/Iconify";
import {
  _customStandardPricingPlan,
  _customPremiumPricingPlan,
  _customBasicPricingPlan,
  _customPricingPlan,
} from "../../utils/data";
import PriceListItem from "./PriceListItem";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  button: {
    background: "#5335CA",
    color: "white",
    // borderRadius: "20px",
    marginBottom: "10px",
    "&:hover": {
      background: "#5335CA",
    },
  },
}));

const RootStyleCard = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  marginTop: 30,
  display: "flex",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "20px",
  boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.06)",
  //   padding: theme.spacing(3),
  //   [theme.breakpoints.up(414)]: {
  //     padding: theme.spacing(5),
  //   },
  padding: "10px",
}));

// ----------------------------------------------------------------------

const PricingPlanCustomCard = () => {
  const classess = useStyles();

  const onSubmit = async () => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwt(token.replace("Bearer ", "")); // decode your token here

    try {
      // await axios
      //   .post(
      //     `saas/payment`,
      //     { id, company: decodedToken.company },
      //     {
      //       config: { handlerEnabled: true },
      //     }
      //   )
      //   .then((response) => {
      //     window.location.href = response.data.data.url;
      //   });
      await axios
        .get("faculty/stripe/session", {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          window.location.replace(response.data.data.url);
        });
    } catch (e) {
      if (e.response && e.response.data) {
      }
    }
  };

  return (
    <RootStyleCard>
      <Stack
        sx={{
          display: "flex",
          alignItems: "end",
          width: "100%",
          height: 26,
        }}
      ></Stack>
      <Typography variant="h5">Custom Package</Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
        <Typography variant="h5" sx={{ color: "text.secondary" }}>
          £
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            mx: 1,
          }}
          variant="h2"
        >
          100
        </Typography>
        <Typography
          gutterBottom
          component="span"
          variant="body1"
          sx={{
            alignSelf: "flex-end",
            color: "text.secondary",
          }}
        >
          /subscription
        </Typography>
      </Box>

      <Typography
        variant="body1"
        sx={{
          color: "primary.main",
          textTransform: "capitalize",
        }}
      >
        {"Forever"}
      </Typography>

      {/* <Box sx={{ width: 80, height: 80, mt: 3, mb: 2 }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          src={product.images[0]}
          alt=""
        />
      </Box> */}

      {/* PRICING ITEMS SHOWS */}
      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1, px: 1 }}>
        {_customPricingPlan.lists.map((item) => (
          <PriceListItem item={item} />
        ))}
      </Stack>

      <Button
        fullWidth
        size="large"
        onClick={() => onSubmit()}
        className={classess.button}
      >
        Continue
      </Button>
    </RootStyleCard>
  );
};

export default PricingPlanCustomCard;

PricingPlanCustomCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
};
