import axios from "axios";
import React, { Component } from "react";
import { useLocation } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.reportError(error, errorInfo);
  }

  reportError = async (error, errorInfo) => {
    if (window && !window.origin.includes("localhost")) {
      let response = await axios.post("/general/report-error", {
        // asPath,
        error,
        errorInfo,
      });
      console.log("Error Reported:", response);
    }
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ display: "block", margin: "auto" }}>
          <img src="/swr.png" alt="" />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
