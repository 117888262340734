import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import "./Visitor.css";
import RefreshButton from "../../components/RefreshButton/Refresh";
import LearnerListNew from "../../components/LearnerListNew/LearnerListNew";
import ProfileContainer from "../../components/ProfileContainer/ProfileContainer";
import HelpAndSupport from "../../components/HelpAndSupport/HelpAndSupport";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Container,
  Card,
  Chip,
  CardContent,
  LinearProgress,
  Typography,
  Skeleton,
} from "@mui/material";
import { getLearners } from "../../redux/slices/learner";
import { useDispatch } from "../../redux/store";
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },
  divStyle3: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  divStyle4: {
    display: "flex",
  },
  labelstyle:{
    paddingLeft:"10",
  },
  field: {
    marginBottom: 20,
  },
  formStylee: {
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    justifyContent: "space-between",
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  typoText1: {
    fontFamily: "Poppins",
    fontSize: 9,
    fontWeight: 600,
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 10,
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
  },
  profileCardText: {
    fontSize: 16,
    color: "white",
  },
  profileCardText1: {
    color: "white",
    fontSize: 12,
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  text2: {
    marginTop: "30px",
    // marginLeft: "25px",
  },
  text1Sub: {
    fontSize: "1.1rem",
  },

  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  barOne: {
    color: "#B4A6EB",
  },
  barTwo: {
    color: "#7CBD1A",
  },
  barThree: {
    color: "#BD1A41",
  },
  barFour: {
    color: "#0000FF",
  },
  barFive: {
    color: "#FACE6B",
  }, barSix: {
    color: "#BABBBC",
  },barSeven: {
    color: "#3ACFF8",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    marginTop: 20,
  },
}));

export const VisitorPage = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [visitorData, setVisitorData] = useState(null);
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, updateMessage } = useContext(AppContext);

  let { id } = useParams();
  let url = "faculty/visitor/".concat(id);
  let helpAndSupportMessage = `Feel free to contact us and get support from the ${
    visitorData && visitorData.visitor.rawCompanyName
  } admin team.`;
  const isDesktop = window.innerWidth > 899 ? true : false;

  const getUserData = async () => {
    try {
      await axios
        .get(url, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setVisitorData(response.data.data);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  const getAllLearners = async () => {
    dispatch(getLearners(id));
  };

  useEffect(() => {
    getUserData();
    getAllLearners();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Fetch All Data When User Back TYo Page After Visiting Another Tab
  const onVisibilityChange = async () => {
    let hidden;
    // let visibilityChange;

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      // visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      // visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      // visibilityChange = "webkitvisibilitychange";
    }

    if (!document[hidden]) {
      getUserData();
      getAllLearners();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      component="main"
      maxWidth="xl"
      className="pd-bottom-40"
      sx={{ paddingLeft: "0", paddingRight: "0" }}
    >
      <Container maxWidth="xl">
        <Box>
          {!isDesktop && (
            <ProfileContainer userData={visitorData && visitorData.visitor} />
          )}
        </Box>

        <Fragment>
          {data ? (
            <Box className={classes.boxStyle}>
              <div className="totalPerformance">
                <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
                  Total Performance
                </Typography>
                <RefreshButton />
              </div>

              <Grid container className="totalPerformanceBoxVisitorPage">
                 <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners signed up
                    </Typography>
                    <Typography component="p" variant="h4">
                      {visitorData && visitorData.overAllPerformance.learnersSignedUp}
                    </Typography>
                  </Fragment>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners on programme
                      {/* <img src="/image2.png" className={classes.qIcon} /> */}
                    </Typography>
                    <Typography component="p" variant="h4">
                      {visitorData &&
                        visitorData.overAllPerformance.onProgramme}
                    </Typography>
                  </Fragment>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners on target
                    </Typography>
                    <Typography component="p" variant="h4">
                      {visitorData && visitorData.overAllPerformance.onTarget}
                    </Typography>
                  </Fragment>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners below target
                    </Typography>
                    <Typography component="p" variant="h4">
                      {visitorData &&
                        visitorData.overAllPerformance.belowTarget}
                    </Typography>
                  </Fragment>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners over target
                    </Typography>
                    <Typography component="p" variant="h4">
                      {visitorData && visitorData.overAllPerformance.overTarget}
                    </Typography>
                  </Fragment>
                </Grid> */}
              </Grid>
            </Box>
          ) : (
            <div className={classes.skeleton}>
              <Skeleton variant="text" height={45} />
              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "0 -10px" }}
              >
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      </Container>

      <Grid container className="mainSection">
        <Grid item xs={12} md={8.5}>
          <Container maxWidth="xl"
          //  style={{ height: "calc(100% - 20px)" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              className={`learnerListWrapper ${classes.boxStyle}`}
            >
              <Fragment>
                {data ? (
                  <Typography
                    className={`listHeader ${classes.divStyle}`}
                    variant="h6"
                    component="h3"
                    sx={{ flexGrow: 1 }}
                  >
                    All Learners
                  </Typography>
                ) : (
                  <Skeleton variant="text" height={45} />
                )}
              </Fragment>

              <LearnerListNew maxHeight={550}></LearnerListNew>
            </Grid>

            {!isDesktop && data && data._id === id && (
              <HelpAndSupport message={helpAndSupportMessage} />
            )}
          </Container>
        </Grid>

        <Grid item xs={12} md={3.5}>
          <Container maxWidth="xl">
            {isDesktop && (
              <ProfileContainer userData={visitorData && visitorData.visitor} />
            )}

            {isDesktop && data && data._id === id && (
              <HelpAndSupport message={helpAndSupportMessage} />
            )}
           
            <Fragment>
              {data ? (
                <Box className={classes.cardStyle}>
                  <Card>
                    <CardContent className="card-content">
                      <Typography variant="h6" gutterBottom>
                        Learners Progress
                      </Typography>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>On Target</Typography>
                          <label>
                            {visitorData &&
                              visitorData.overAllProgress.onTarget}
                            %
                          </label>
                        </div>
                        <LinearProgress
                          className={classes.barTwo}
                          color="inherit"
                          value={
                            visitorData && visitorData.overAllProgress.onTarget
                          }
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>Below Target</Typography>
                          <label>
                            {visitorData &&
                              visitorData.overAllProgress.belowTarget}
                            %
                          </label>
                        </div>
                        <LinearProgress
                          className={classes.barThree}
                          color="inherit"
                          value={
                            visitorData &&
                            visitorData.overAllProgress.belowTarget
                          }
                          variant="determinate"
                        />
                      </Box>
                      {/* <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>Over Target</Typography>
                          <label>
                            {visitorData &&
                              visitorData.overAllProgress.overTarget}
                            %
                          </label>
                        </div>
                        <LinearProgress
                          value={
                            visitorData &&
                            visitorData.overAllProgress.overTarget
                          }
                          variant="determinate"
                          className={classes.barFour}
                          color="inherit"
                        />
                      </Box> */}
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                </div>
              )}
            </Fragment>

            <Box className={classes.cardStyle}>
                    <Card>
                      <CardContent className="card-content">
                        <Typography variant="h6" gutterBottom>
                          Learners Data
                        </Typography>

                        <Box
                          sx={{
                            width: "100%",
                            marginTop: 2.5,
                            marginBottom: 2.5,
                          }}
                        >
                          <div className={classes.divStyle2}>
                            <Typography gutterBottom>On Programme
                            <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#FACE6B",
                            border: `1px solid #FACE6B`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            visitorData &&
                            visitorData.overAllProgress.onProgrammeCount &&
                            visitorData.overAllProgress.onProgrammeCount
                          }
                        />
                            </Typography>
                            <label>
                              {visitorData &&
                               visitorData.overAllProgress.onProgramme &&
                               visitorData.overAllProgress.onProgramme}
                              %
                            </label>
                          </div>
                          <LinearProgress
                            color="inherit"
                            className={classes.barFive}
                            value={
                              visitorData &&
                              visitorData.overAllProgress.onProgramme &&
                              visitorData.overAllProgress.onProgramme
                            }
                            variant="determinate"
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            marginTop: 2.5,
                            marginBottom: 2.5,
                          }}
                        >
                          <div className={classes.divStyle2}>
                            <Typography gutterBottom>On break
                            <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#3ACFF8",
                            border: `1px solid #3ACFF8`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            visitorData &&
                            visitorData.overAllProgress.onBreakCount &&
                            visitorData.overAllProgress.onBreakCount
                          }
                        />
                            </Typography>
                            <label>
                              {visitorData &&
                              visitorData.overAllProgress.onBreak &&
                                visitorData.overAllProgress.onBreak}
                              %
                            </label>
                          </div>
                          <LinearProgress
                            color="inherit"
                            className={classes.barSeven}
                            value={
                              visitorData &&
                              visitorData.overAllProgress.onBreak &&
                              visitorData.overAllProgress.onBreak
                            }
                            variant="determinate"
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            marginTop: 2.5,
                            marginBottom: 2.5,
                          }}
                        >
                          <div className={classes.divStyle2}>
                            <Typography gutterBottom>Off Programme
                            <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#BABBBC",
                            border: `1px solid #BABBBC`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            visitorData &&
                            visitorData.overAllProgress.offProgrammeCount &&
                            visitorData.overAllProgress.offProgrammeCount
                          }
                        />
                            </Typography>
                            <label>
                              {visitorData &&
                                visitorData.overAllProgress.offProgramme &&
                                visitorData.overAllProgress.offProgramme}
                              %
                            </label>
                          </div>
                          <LinearProgress
                            color="inherit"
                            className={classes.barSix}
                            value={
                              visitorData &&
                              visitorData.overAllProgress.offProgramme &&
                              visitorData.overAllProgress.offProgramme
                            }
                            variant="determinate"
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            marginTop: 2.5,
                            marginBottom: 2.5,
                          }}
                        >
                          <div className={classes.divStyle2}>
                            <Typography gutterBottom>Completed
                            <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#7CBD1A",
                            border: `1px solid #7CBD1A`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            visitorData &&
                            visitorData.overAllProgress.completedCount &&
                            visitorData.overAllProgress.completedCount
                          }
                        />
                            </Typography>
                            <label>
                              {visitorData &&
                                visitorData.overAllProgress.completed &&
                                visitorData.overAllProgress.completed}
                              %
                            </label>
                          </div>
                          <LinearProgress
                            color="inherit"
                            className={classes.barTwo}
                            value={
                              visitorData &&
                              visitorData.overAllProgress.completed &&
                              visitorData.overAllProgress.completed
                            }
                            variant="determinate"
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>

          </Container>
        </Grid>

       
      </Grid>
    </Container>
  );
};
