import { Box, Typography, Card, Button, Chip, Stack } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";
import axios from "axios";
import jwt from "jwt-decode";
import { makeStyles } from "@mui/styles";
import Iconify from "../../components/Iconify";
import {
  _customStandardPricingPlan,
  _customPremiumPricingPlan,
  _customBasicPricingPlan,
  _customPricingPlan,
} from "../../utils/data";
import PriceListItem from "./PriceListItem";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  button: {
    background: "#5335CA",
    color: "white",
    // borderRadius: "20px",
    marginBottom: "10px",
    "&:hover": {
      background: "#5335CA",
    },
  },
}));

const RootStyleCard = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  marginTop: 30,
  display: "flex",
  position: "relative",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "20px",
  boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.06)",
  //   padding: theme.spacing(3),
  //   [theme.breakpoints.up(414)]: {
  //     padding: theme.spacing(5),
  //   },
  padding: "10px",
}));

// ----------------------------------------------------------------------

const PricingPlanCard = ({ card, index, quantity }) => {
  const classess = useStyles();
  const { id, product, recurring, unit_amount } = card;

  const onSubmit = async (id, name) => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwt(token.replace("Bearer ", "")); // decode your token here

    try {
      if (name == "Custom") {
        await axios
          .post(
            "faculty/stripe/session",
            { id },
            {
              config: { handlerEnabled: true },
            }
          )
          .then((response) => {
            window.location.replace(response.data.data.url);
          });
      } else {
        await axios
          .post(
            `saas/payment`,
            { id, company: decodedToken.company, quantity },
            {
              config: { handlerEnabled: true },
            }
          )
          .then((response) => {
            // setPricing(response.data.data);
            //   console.log(response.data.data.url);
            window.location.href = response.data.data.url;
          });
      }
    } catch (e) {
      if (e.response && e.response.data) {
      }
    }
  };

  return (
    <RootStyleCard>
      <Stack
        sx={{
          display: "flex",
          alignItems: "end",
          width: "100%",
          height: 26,
        }}
      >
        {index === 1 && (
          <Chip
            //   color="info"
            color="primary"
            variant="outlined"
            sx={{
              border: "2px solid",
              borderRadius: "20px",
              height: 22,
              width: 80,
              boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.09)",
            }}
            label={
              <Typography fontWeight={"bold"} variant="body1">
                POPULAR
              </Typography>
            }
          ></Chip>
        )}
      </Stack>
      <Typography variant="h5">{product.name}</Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
        <Typography variant="h5" sx={{ color: "text.secondary" }}>
          £
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            mx: 1,
          }}
          variant="h2"
        >
          {(unit_amount / 100) * quantity}
        </Typography>
        <Typography
          gutterBottom
          component="span"
          variant="body1"
          sx={{
            alignSelf: "flex-end",
            color: "text.secondary",
          }}
        >
          /{recurring.interval}
        </Typography>
      </Box>

      <Typography
        variant="body1"
        sx={{
          color: "primary.main",
          textTransform: "capitalize",
        }}
      >
        {product.description || "Forever"}
      </Typography>

      {/* {index != 3 && ( */}
      <Box sx={{ width: 80, height: 80, mt: 3, mb: 2 }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          src={product.images[0]}
          alt=""
        />
      </Box>
      {/* )} */}

      {/* PRICING ITEMS SHOWS */}
      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1, px: 1 }}>
        {product.name === "Premium" &&
          _customPremiumPricingPlan.lists.map((item) => (
            <PriceListItem item={item} />
          ))}
        {product.name === "Standard" &&
          _customStandardPricingPlan.lists.map((item) => (
            <PriceListItem item={item} />
          ))}
        {product.name === "Basic" &&
          _customBasicPricingPlan.lists.map((item) => (
            <PriceListItem item={item} />
          ))}
        {/* {product.name === "Custom" &&
          _customPricingPlan.lists.map((item) => <PriceListItem item={item} />)} */}
      </Stack>

      <Button
        fullWidth
        size="large"
        onClick={() => onSubmit(id, product.name)}
        className={classess.button}
      >
        Choose {product.name}
      </Button>
    </RootStyleCard>
  );
};

export default PricingPlanCard;

PricingPlanCard.propTypes = {
  index: PropTypes.number,
  card: PropTypes.object,
};
