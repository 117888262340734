import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import "./Navbar.css";
import CompanyListModal from "../../components/CompanyListModal/CompanyListModal";
import EditProfileModal from "../../components/EditProfileModal/EditProfileModal";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Avatar,
  AppBar,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Business as BusinessIcon,
  Edit as EditIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { AppContext } from "../../context/appContext";
import { withRouter } from "react-router-dom";
import { isEqual } from "lodash";
import { titleCase } from "change-case-all";
import { capitalCase } from "capital-case";

const NavBar = (props) => {
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [companies, setCompanies] = useState("");

  // To set the title of the pages
  useEffect(() => {
    if (data) {
      let pageName = props.location.pathname.split("/")[1];
      pageName = pageName != "admin" ? pageName : data.attribute;
      document.title =
        capitalCase(pageName, "PascalCase") + " | " + data.rawCompanyName;

      // if (data.role == "learner") {
      //   document.title = `${titleCase(data.role)} | ${data.rawCompanyName}`;
      // } else {
      //   document.title = `${titleCase(data.attribute)} | ${
      //     data.rawCompanyName
      //   }`;
      // }
    }
  }, [data, props.location]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload(false);
  };

  const fetchCompanies = async () => {
    try {
      // handleToggle(true);
      await axios
        .get(`general/companies/${data.email}`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          // handleToggle(false);
          if (!isEqual(companies, response.data.data)) {
            setCompanies(response.data.data);
          }
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (data) fetchCompanies();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Companies When User Returns Back From Another Tab
  const onVisibilityChange = async () => {
    let hidden;
    // let visibilityChange;

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      // visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      // visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      // visibilityChange = "webkitvisibilitychange";
    }

    if (!document[hidden]) {
      if (data) fetchCompanies();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ background: "#2D2C40" }}>
          <Container component="main" maxWidth="xl">
            <Toolbar
              sx={{
                justifyContent: "space-between",
                // padding: { xl: 0 },
              }}
            >
              <Link to={data ? `/${data.role}/${data._id}` : "/"}>
                <img src="/image.png" alt="" className="headerLogo" />
              </Link>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>
              {data ? (
                <div className="headerProfileArea">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <Avatar
                      alt={data.name}
                      src={data.imageUrl}
                      sx={{ width: 46, height: 46 }}
                    />
                    <Typography style={{ paddingLeft: "10px" }}>
                      {data.name}
                    </Typography>
                    <ArrowDropDownIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{
                      marginTop: 50,
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <Avatar
                          alt={data.name}
                          src={data.imageUrl}
                          sx={{ width: 46, height: 46 }}
                        />
                      </ListItemIcon>
                      <div style={{ display: "flex" }}>
                        <div>
                          <Typography
                            style={{
                              paddingLeft: "10px",
                              fontSize: 15,
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              width: "120px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data.name}
                          </Typography>
                          <Typography
                            style={{
                              textTransform: "capitalize",
                              paddingLeft: "10px",
                              fontSize: 10,
                            }}
                          >
                            {data.attribute ? data.attribute : 'Learner'}
                          </Typography>
                        </div>
                        <IconButton
                          onClick={() => {
                            setShowEditProfileModal(!showEditProfileModal);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </MenuItem>

                    <hr />
                    {companies && companies.length > 1 && (
                      <MenuItem
                        style={{ paddingLeft: 35 }}
                        onClick={() => setShowCompanyModal(true)}
                      >
                        <ListItemIcon>
                          <BusinessIcon fontSize="small" />
                        </ListItemIcon>
                        Change Organisation
                      </MenuItem>
                    )}

                    <MenuItem style={{ paddingLeft: 35 }} onClick={logOut}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
              ) : null}
            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      <CompanyListModal
        open={showCompanyModal}
        close={() => setShowCompanyModal(false)}
        companies={companies}
      />

      <EditProfileModal
        open={showEditProfileModal}
        close={() => setShowEditProfileModal(false)}
      />
    </Fragment>
  );
};

export default withRouter(NavBar);
