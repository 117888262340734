import React, { useContext, useEffect, useState } from "react";
import { AdminPage } from "./pages/Admin/AdminPage";
import Layout from "./components/Layout/Layout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { ManagerPage } from "./pages/Manager/ManagerPage";
import { VisitorPage } from "./pages/Visitor/VisitorPage";
import { SignIn } from "./components/SignIn/SignIn";
import { ResetPassword } from "./components/ResetPassword/ResetPassword";
import { CoachPage } from "./pages/Coach/CoachPage";
import { MentorPage } from "./pages/Mentor/MentorPage";
import { LearnerPage } from "./pages/Learner/LearnerPage";
import { TrainerPage } from "./pages/Trainer/TrainerPage";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import PrivateRoute from "./utils/private-routes";
import { AppContext, AppProvider } from "./context/appContext";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import OfflineAlertModal from "./components/OfflineAlertModal/OfflineAlertModal";
import Pricing from "./pages/Pricing/Pricing";
import InvalidSubscription from "./pages/InvalidSubscription/InvalidSubscription";
import UpgradeSubscription from "./pages/UpgradeSubscription/UpgradeSubscription";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";

function App() {
  const learnerPageRoles = [
    "learner",
    "admin",
    "coach",
    "mentor",
    "manager",
    "visitor",
    "trainer",
  ];
  const adminPageRoles = ["admin"];
  const managerPageRoles = ["admin", "manager"];
  const coachPageRoles = ["admin", "coach", "manager"];
  const mentorPageRoles = ["admin", "mentor", "manager"];
  const visitorPageRoles = ["admin", "visitor"];
  const trainerPageRoles = ["admin", "trainer"];

  const [isOffline, setIsOffline] = useState(false);
  window.ononline = () => {
    // Fires When Intenet Backs Online
    setIsOffline(false);
  };

  window.onoffline = () => {
    // Fires When Intenet Gets Offline
    setIsOffline(true);
  };

  return (
    <ReduxProvider store={store}>
      <AppProvider>
        <div
          className="App"
          style={{ background: "#E5E5E5", minHeight: "100vh" }}
        >
          <Router>
            {/* <div> */}
            <ThemeProvider theme={theme}>
              <Layout>
                <Switch>
                  <ErrorBoundary>
                    <CssBaseline />
                    <Route exact path="/" component={SignIn} />
                    <Route exact path="/pricing" component={Pricing} />
                    <Route
                      exact
                      path="/upgrade-subscription"
                      component={UpgradeSubscription}
                    />
                    <Route
                      exact
                      path="/billing"
                      component={InvalidSubscription}
                    />
                    <Route
                      exact
                      path="/forget-password"
                      component={ResetPassword}
                    />

                    <PrivateRoute
                      exact
                      path="/admin/:id"
                      component={AdminPage}
                      roles={adminPageRoles}
                    />
                    <PrivateRoute
                      path="/manager/:id"
                      component={ManagerPage}
                      roles={managerPageRoles}
                    />
                    <PrivateRoute
                      path="/coach/:id"
                      component={CoachPage}
                      roles={coachPageRoles}
                    />
                    <PrivateRoute
                      path="/mentor/:id"
                      component={MentorPage}
                      roles={mentorPageRoles}
                    />
                    <PrivateRoute
                      path="/learner/:id"
                      component={LearnerPage}
                      roles={learnerPageRoles}
                    />
                    <PrivateRoute
                      path="/visitor/:id"
                      component={VisitorPage}
                      roles={visitorPageRoles}
                    />
                    <PrivateRoute
                      path="/trainer/:id"
                      component={TrainerPage}
                      roles={trainerPageRoles}
                    />
                  </ErrorBoundary>
                </Switch>
              </Layout>

              <OfflineAlertModal
                open={isOffline}
                close={() => setIsOffline(false)}
              />
            </ThemeProvider>
            {/* </div> */}
          </Router>
        </div>
      </AppProvider>
    </ReduxProvider>
  );
}

export default App;
