import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
}));

const RefreshButton = () => {
  const classes = useStyles();
  const refreshPage = () => {
    window.location.reload(false);
  };

  return (
    <Button
      className={classes.button}
      onClick={refreshPage}
      startIcon={<RefreshIcon />}
    >
      Refresh
    </Button>
  );
};
export default RefreshButton;
