import React from "react";
import { Grid, Container, Typography, Stack, TextField } from "@mui/material";
import PricingPlanCard from "./PricingPlanCard";
import PricingPlanCustomCard from "./PricingPlanCustomCard";
import { useState } from "react";

const ChoosePlane = ({ pricing }) => {
  const [quantity, setQuantity] = useState(10);

  return (
    <Container
      maxWidth="xl"
      sx={{
        my: 3,
      }}
    >
      <Stack mb={5} direction="column">
        <Typography
          fontWeight={"bold"}
          variant="h4"
          align="center"
          paragraph
          gutterBottom
        >
          Flexible plans for your
          <br /> apprenticeship programme
        </Typography>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Typography
            variant="h5"
            align="center"
            sx={{ color: "text.secondary" }}
          >
            How many apprentices do you have?&nbsp;
          </Typography>
          <TextField
            required
            type="number"
            min="1"
            id="outlined-basic"
            placeholder="Quantity"
            // label="Qunatity"
            name="quantity"
            value={quantity}
            onChange={(e) => {
              if (e.target.value > 0) {
                setQuantity(e.target.value);
              }
            }}
            variant="outlined"
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Stack>
      </Stack>

      <Grid container spacing={2}>
        {pricing.map((card, index) => (
          <Grid item xs={12} md={4} key={card.id}>
            <PricingPlanCard card={card} index={index} quantity={quantity} />
          </Grid>
        ))}
        {/* <Grid item xs={12} md={3} key={Math.random()}>
          <PricingPlanCustomCard />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default ChoosePlane;
