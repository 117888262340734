import React, { useState, Fragment, useContext } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { TextField, IconButton, Menu, MenuItem, Skeleton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getHours } from "../../utils/functions";
import {
  DataGrid,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridOverlay,
  getGridStringOperators,
} from "@mui/x-data-grid";
import { AppContext } from "../../context/appContext";
import { RestrictFeature } from "../RestrictFeature/RestrictFeature";
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 1.5rem",
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    marginLeft: "6px",
  },
  noDataImage: { display: "flex", justifyContent: "center" },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    marginTop: 20,
  },

  learnerList: {
    height: "350px",
    width: "100%",
    marginTop: "15px",
    "@media (max-width: 900px)": {
      height: "400px",
    },
  },
}));

function QuickSearchToolbar(props) {
  const classes = useStyles();
  const { data } = useContext(AppContext);

  return (
    <div className={`listCardTop ${classes.root}`}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <div className="listCardTop__buttons">
        <RestrictFeature available={["standard", "premium"]}>
          <GridToolbarExport
            csvOptions={{ allColumns: true }}
            // printOptions={{
            //   hideFooter: true,
            //   hideToolbar: true,
            // }}
            printOptions={{ disableToolbarButton: true }}
          />
        </RestrictFeature>
        <GridToolbarFilterButton xs={{ maxidth: "auto" }} />
        {/* <GridToolbarDensitySelector /> */}
      </div>
    </div>
  );
}

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function CustomNoRowsOverlay() {
  const classes = useStyles();

  return (
    <GridOverlay>
      <div className={classes.noDataImage}>
        <img src="/no-data-found.png" alt="" />
      </div>
    </GridOverlay>
  );
}

export const LearnerList = (props) => {
  const classes = useStyles();
  const pageRole = window.location.pathname.split("/")[1];
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState(props.data);
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);

  const getFullName = (params) => {
    return `${params.getValue(params.id, "name")}`;
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals" /* add more over time */].includes(value)
  );

  const containsFilterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains" /* add more over time */].includes(value)
  );

  // const isDesktop = window.innerWidth > 899 ? true : false;
  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [tempColumnLearner, setTempColumnLearner] = useState({});

  const columnsGrid = [
    {
      field: "active",
      headerName: " ",
      width: 10,
      renderCell: (params) => {
        let isActive = params.row.active;

        return (
          <span
            title={`${isActive ? "Active" : "In Active"}`}
            className={`activeIndicator ${isActive ? "active" : ""}`}
          ></span>
        );
      },
      // hide: data && data.role === 'admin' ? true : false,
      sortable: false,
      filterable: false,
      disableExport: true,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      valueGetter: getFullName,
      sortable: true,
      disableColumnMenu: true,
      filterOperators: containsFilterOperators,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   width: 180,
    //   sortable: true,
    //   disableColumnMenu: true,
    //   filterOperators: containsFilterOperators,
    // },
    {
      field: pageRole === "coach" ? "employerName" : "coachMentor",
      headerName: pageRole === "coach" ? "Employer" : "Coach",
      width: 100,
      //    valueGetter: coachName,
      renderCell: (params) => {
        if (pageRole !== "coach") {
          let index = params.row.coachMentor.findIndex(
            (x) => x.role === "coach"
          );
          if (index !== -1) {
            let coach = params.row.coachMentor[index];
            return (
              <span className="learnerListCoachs" title={coach.name}>
                {pageRole !== "mentor" &&
                pageRole !== "visitor" &&
                pageRole !== "trainer" ? (
                  <a
                    href={`${window.location.origin}/coach/${coach._id}`}
                    rel="noreferrer"
                    target="_blank"
                    title={coach.name}
                  >
                    {coach.name}
                  </a>
                ) : (
                  coach.name
                )}
              </span>
            );
          }
          return "N / A";
        } else {
          return params.row.employerName;
        }
      },
      valueFormatter: (params) => {
        if (pageRole !== "coach") {
          let index = params.value.findIndex((x) => x.role === "coach");
          if (index !== -1) {
            let coach = params.value[index];
            return `${coach.name}`;
          }
          return "N / A";
        } else {
          return params.value;
        }
      },
      sortable: false,
      disableColumnMenu: true,
      filterOperators: containsFilterOperators,
    },
    {
      field: "status",
      headerName: "Status",
      width: 105,
      sortable: false,
      disableColumnMenu: true,
      //   align: 'center'
      filterOperators,
    },
    // {
    //   field: "targetStatus",
    //   headerName: "Target Status",
    //   width: 130,
    //   // hide: true,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   filterOperators,
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 85,
      renderCell: (params) => {
        let learner = params.row;

        return (
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleClick(e);
                setTempColumnLearner(learner);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "fit-content",
                  minWidth: "160px",
                },
              }}
            >
              <MenuItem
                key={1 * Math.random()}
                onClick={() => {
                  handleClose();
                  visitLearnerPage(tempColumnLearner._id);
                }}
              >
                <PersonOutlineSharpIcon
                  fontSize="small"
                  className={classes.icons}
                />
                &nbsp;&nbsp;Profile
              </MenuItem>
              <MenuItem
                key={2 * Math.random()}
                onClick={() => {
                  handleClose();
                  downloadLearnerReport(tempColumnLearner._id);
                }}
              >
                <GetAppIcon fontSize="small" className={classes.icons} />
                &nbsp;&nbsp;Download Learner Report
              </MenuItem>
              <MenuItem
                key={2 * Math.random()}
                onClick={() => {
                  handleClose();
                  downloadEvidenceReport(tempColumnLearner._id);
                }}
              >
                <GetAppIcon fontSize="small" className={classes.icons} />
                &nbsp;&nbsp;Download OTJ Evidence Collection Report
              </MenuItem>
              {pageRole === "admin" && (
                <MenuItem
                  key={3 * Math.random()}
                  onClick={() => {
                    handleClose();
                    props.edit(tempColumnLearner);
                  }}
                >
                  <EditIcon fontSize="small" className={classes.icons} />
                  &nbsp;&nbsp;Edit
                </MenuItem>
              )}

              {pageRole === "admin" && (
                <MenuItem
                  key={4 * Math.random()}
                  onClick={() => {
                    handleClose();
                    props.delete(tempColumnLearner);
                  }}
                >
                  <DeleteOutlineIcon
                    fontSize="small"
                    className={classes.icons}
                  />
                  &nbsp;&nbsp;Delete
                </MenuItem>
              )}
            </Menu>
          </div>
        );
      },
      sortable: false,
      filterable: false,
      disableExport: true,
      disableColumnMenu: true,
    },
    {
      field: "weekNumber",
      headerName: "Actual Practical Period Week(s)",
      renderCell: (params) => {
        // console.lo(params);
        return <span>{params.row.progress.weekNumber}</span>;
      },
      valueGetter: (params) => {
        // console.log(params);
        return params.row.progress.weekNumber;
      },
      valueFormatter: (params) => {
        // console.log(params);
        return params.value;
      },
      hide: true,
      filterable: false,
      filterOperators,
    },
    {
      field: "currentTarget",
      headerName: "Actual Week Target hr/min",
      renderCell: (params) => {
        let { hours, minutes } = getHours(params.row.progress.currentTarget);
        return (
          <span>
            {hours}hr.{minutes}min
            {/* Test */}
          </span>
        );
      },
      valueGetter: (params) => {
        return params.row.progress.currentTarget;
      },
      valueFormatter: (params) => {
        let { hours, minutes } = getHours(params.value);
        return `${hours}hr.${minutes}min`;
      },
      hide: true,
      filterable: false,
      filterOperators,
    },
    {
      field: "completedTarget",
      headerName: "Total Completed to date hr/min",
      // renderCell: (params) => {
      //   let { hours, minutes } = getHours(params.row.progress.cureentProgress);
      //   return (
      //     <span>
      //       {hours}hr.{minutes}min
      //     </span>
      //   );
      // },
      valueGetter: (params) => {
        return params.row.progress.cureentProgress;
      },
      valueFormatter: (params) => {
        let { hours, minutes } = getHours(params.value);
        return `${hours}hr.${minutes}min`;
      },
      hide: true,
      filterable: false,
      filterOperators,
    },
    {
      field: "totalSeconds",
      headerName: "Planned OTJ Training hr/min",
      // renderCell: (params) => {
      //   let { hours, minutes } = getHours(params.row.totalSeconds);
      //   return (
      //     <span>
      //       {hours}hr.{minutes}min
      //     </span>
      //   );
      // },
      valueFormatter: (params) => {
        let { hours, minutes } = getHours(params.value);
        return `${hours}hr.${minutes}min`;
      },
      hide: true,
      filterable: false,
      filterOperators,
    },
    {
      field: "monthlyHourMinutes",
      headerName: "Practical Period Week(s)",
      // renderCell: (params) => {
      //   return (
      //     <span>
      //       {/* {hours}hr.{minutes}min */}
      //       {params.row.monthlyHourMinutes.length - 1}
      //     </span>
      //   );
      // },
      valueFormatter: (params) => {
        // let { hours, minutes } = getHours(params.row.totalSeconds);
        return params.value.length;
      },
      hide: true,
      filterable: false,
      filterOperators,
    },
    {
      field: "targetStatus",
      headerName: "Learner Progression",
      hide: true,
      filterable: false,
      filterOperators,
    },
  ];

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = props.data.filter((row) => {
      return Object.keys(row).some((field) => {
        if (field === "coachMentor") {
          let index = row[field].findIndex((x) => x.role === "coach");
          if (index !== -1) {
            return (
              searchRegex.test(row[field][index].email.toString()) ||
              searchRegex.test(row[field][index].name.toString())
            );
          }
        }
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(props.data);
    setSearchText("");
  }, [props.data]);

  const visitLearnerPage = (id) => {
    window.open(`${window.origin}/learner/${id}`);
  };

  // To Download The Learner Report
  const downloadLearnerReport = async (id) => {
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-OTJ-report/${id}`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${tempColumnLearner.name}'s report`);
          link.href = fileURL;

          // Auto click the link
          link.click();

          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Download The Learner Evidence Report
  const downloadEvidenceReport = async (id) => {
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-evidence-report/${id}`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${tempColumnLearner.name}'s report`);
          link.href = fileURL;

          // Auto click the link
          link.click();

          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  return (
    <Fragment>
      {data ? (
        <div className={classes.learnerList}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                getRowId={(r) => r._id}
                components={{
                  Toolbar: QuickSearchToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  NoResultsOverlay: CustomNoRowsOverlay,
                }}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination
                rows={rows}
                columns={columnsGrid}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(""),
                  },
                }}
                selectionModel={[]}
                rowHeight={40}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.skeleton} style={{ padding: 0 }}>
          <Skeleton variant="rect" height={335} />
        </div>
      )}
    </Fragment>
  );
};
