import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Autocomplete,
  Typography,
  Checkbox,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Drawer,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { getLearners, setAllLearners } from "../../redux/slices/learner";
import { useDispatch, useSelector } from "../../redux/store";
import { useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 20,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
}));

const UpdateLearner = ({
  open,
  close,
  tempLearner,
  setTempLearner,
  newLearnerValidations,
}) => {

  console.log(tempLearner)
  const dispatch = useDispatch();

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  let { id } = useParams();

  const { handleToggle, updateMessage } = useContext(AppContext);
  const { allFaculties } = useSelector((state) => state.faculty);
  const [filteredFaculties, setFilteredFaculties] = useState({
    coach: [],
    manager: [],
    mentor: [],
    visitor: [],
    trainer: [],
  });
  const [learnerValidations, setLearnerValidations] = useState(
    newLearnerValidations
  );

  // FormHandler For Update Learner Modal
  const updateLearnerChangeHandler = (e, selectInput) => {
    if (e && e.target && e.target.name) {
      let { name, value, checked } = e.target;

      if (name && name === "isLearnerActive") {
        setTempLearner({
          ...tempLearner,
          active: checked,
        });
      } else if (name) {
        setTempLearner({
          ...tempLearner,
          [name]: value,
        });

        if (value && learnerValidations[name]) {
          setLearnerValidations({
            ...learnerValidations,
            [name]: false,
          });
        }
      }
    } else if (
      e &&
      (selectInput === "coach" ||
        selectInput === "manager" ||
        selectInput === "mentor" ||
        selectInput === "visitor" ||
        selectInput === "trainer")
    ) {
      setTempLearner({
        ...tempLearner,
        [selectInput]: e,
      });
    }
  };

  // FormHandler For Update Learner Modal CheckBoxes
  const updateLearnerCheckboxHandler = (e) => {
    let { name } = e.target;

    setTempLearner({
      ...tempLearner,
      [name]: !tempLearner[name],
    });
  };

  // To Update Learner
  const updateLearner = async () => {
    // 1- Validations
    // if (tempLearner.uln === "") {
    //   setLearnerValidations({
    //     ...learnerValidations,
    //     uln: true,
    //   });
    //   return;
    // }

    if (tempLearner.name === "") {
      setLearnerValidations({
        ...learnerValidations,
        name: true,
      });
      return;
    }
    if (tempLearner.trainingProviderName === "") {
      setLearnerValidations({
        ...learnerValidations,
        trainingProviderName: true,
      });
      return;
    }
    if (tempLearner.employerName === "") {
      setLearnerValidations({
        ...learnerValidations,
        employerName: true,
      });
      return;
    }
    if (tempLearner.apprenticeshipStandard === "") {
      setLearnerValidations({
        ...learnerValidations,
        apprenticeshipStandard: true,
      });
      return;
    }

    // 2- Assignments
    let coachMentor = allFaculties.filter(
      (faculty) =>
        faculty.email === tempLearner.coach ||
        faculty.email === tempLearner.manager ||
        faculty.email === tempLearner.mentor ||
        faculty.email === tempLearner.visitor ||
        faculty.email === tempLearner.trainer
    );

    let learner = {
      ...tempLearner,
      coachMentor: coachMentor,
      active: tempLearner.active,
    };

    // 3- Api
    try {
      handleToggle(true);
      await axios
        .put("learner", learner, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          // dispatch(setAllLearners(response.data.data));
          dispatch(getLearners(id));
          close();
          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Set/ Update The Filtered Faculties When 'faculties' State Changes
  useEffect(() => {
    if (allFaculties && allFaculties.length > 0) {
      let coach = [],
        manager = [],
        mentor = [],
        visitor = [],
        trainer = [];

      for (let faculty of allFaculties) {
        if (faculty?.role === "coach") {
          coach.push(faculty);
        } else if (faculty?.role === "manager") {
          manager.push(faculty);
        } else if (faculty?.role === "mentor") {
          mentor.push(faculty);
        } else if (faculty?.role === "visitor") {
          visitor.push(faculty);
        } else if (faculty?.role === "trainer") {
          trainer.push(faculty);
        }
      }
      setFilteredFaculties({
        coach,
        manager,
        mentor,
        visitor,
        trainer,
      });
    }
  }, [allFaculties]);

  // console.log(tempLearner.coach);
  return (
    // <Modal
    //   open={open}
    //   onClose={close}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Drawer
      anchor="right"
      open={open}
      onClose={close}
      PaperProps={{
        sx: { width: matches ? "40%" : "100%" },
      }}
    >
      <Box sx={{ m: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawerHeader"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Learner
          </Typography>
          <CloseIcon className={classes.icons} title="Close" onClick={close} />
        </Grid>
        <FormGroup sx={{ paddingTop: 3 }}>
          <TextField
            label="ULN (Unique Learner Number)"
            variant="outlined"
            name="uln"
            className={classes.field}
            value={tempLearner.uln}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.uln}
            helperText={learnerValidations.uln && "This can't be empty."}
          />
          <Grid container spacing={3} className={classes.field}>
            <Grid item sm={12} md={12} className="fullWidthInput">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={tempLearner.name}
                onChange={(e) => updateLearnerChangeHandler(e)}
                error={learnerValidations.name}
                helperText={learnerValidations.name && "This can't be empty."}
              />
            </Grid>
          </Grid>

          <TextField
            label="Work Email"
            variant="outlined"
            className={classes.field}
            type="email"
            name="email"
            value={tempLearner.email}
            disabled
          />

          <TextField
            label="Training Provider Name"
            variant="outlined"
            className={classes.field}
            type="email"
            name="trainingProviderName"
            value={tempLearner.trainingProviderName}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.trainingProviderName}
            helperText={
              learnerValidations.trainingProviderName && "This can't be empty."
            }
          />

          <TextField
            label="Employer Name"
            variant="outlined"
            className={classes.field}
            name="employerName"
            value={tempLearner.employerName}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.employerName}
            helperText={
              learnerValidations.employerName && "This can't be empty."
            }
          />

          {/* <Autocomplete
            limitTags={1}
            className={classes.field}
            options={filteredFaculties?.coach}
            getOptionLabel={(option) => option?.email || ""}
            defaultValue={
              filteredFaculties?.coach?.length > 0
                ? filteredFaculties.coach.find(
                    (coach) => coach?.email === tempLearner?.coach
                  )
                : ""
            }
            onChange={(e) => updateLearnerChangeHandler(e, "coach")}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "disabled",
                }}
                label="Coach Email"
                variant="outlined"
              />
            )}
          /> */}

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Coach</InputLabel>
            <Select
              className={classes.field}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tempLearner.coach}
              label="Coach"
              onChange={(e) =>
                updateLearnerChangeHandler(e.target.value, "coach")
              }
              endAdornment={
                <IconButton
                  sx={{ visibility: tempLearner.coach ? "visible" : "hidden" }}
                  onClick={() => {
                    setTempLearner({
                      ...tempLearner,
                      coach: "",
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              }
              sx={{
                "& .MuiSelect-iconOutlined": {
                  display: tempLearner.coach ? "none" : "",
                },
              }}
            >
              {filteredFaculties.coach.map((option) => (
                <MenuItem value={option.email}>{option.email}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <Autocomplete
            limitTags={1}
            className={classes.field}
            options={filteredFaculties?.manager}
            getOptionLabel={(option) => option?.email || ""}
            defaultValue={
              filteredFaculties?.manager?.length > 0
                ? filteredFaculties.manager.find(
                    (manager) => manager?.email === tempLearner?.manager
                  )
                : ""
            }
            onChange={(e) => updateLearnerChangeHandler(e, "manager")}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "disabled",
                }}
                label="Manager Email"
                variant="outlined"
              />
            )}
          /> */}

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Manager</InputLabel>
            <Select
              className={classes.field}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tempLearner.manager}
              label="Manager"
              onChange={(e) =>
                updateLearnerChangeHandler(e.target.value, "manager")
              }
              endAdornment={
                <IconButton
                  sx={{
                    visibility: tempLearner.manager ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    console.log(tempLearner)
                    setTempLearner({
                      ...tempLearner,
                      manager: "",
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              }
              sx={{
                "& .MuiSelect-iconOutlined": {
                  display: tempLearner.manager ? "none" : "",
                },
              }}
            >
              {filteredFaculties.manager.map((option) => (
                <MenuItem value={option.email}>{option.email}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <Autocomplete
            limitTags={1}
            className={classes.field}
            options={filteredFaculties?.mentor}
            getOptionLabel={(option) => option?.email || ""}
            defaultValue={
              filteredFaculties?.mentor?.length > 0
                ? filteredFaculties.mentor.find(
                    (mentor) => mentor?.email === tempLearner?.mentor
                  )
                : ""
            }
            onChange={(e) => updateLearnerChangeHandler(e, "mentor")}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "disabled",
                }}
                label="Mentor Email"
                variant="outlined"
              />
            )}
          /> */}

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Mentor</InputLabel>
            <Select
              className={classes.field}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tempLearner.mentor}
              label="Mentor"
              onChange={(e) =>
                updateLearnerChangeHandler(e.target.value, "mentor")
              }
              endAdornment={
                <IconButton
                  sx={{ visibility: tempLearner.mentor ? "visible" : "hidden" }}
                  onClick={() => {
                    setTempLearner({
                      ...tempLearner,
                      mentor: "",
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              }
              sx={{
                "& .MuiSelect-iconOutlined": {
                  display: tempLearner.mentor ? "none" : "",
                },
              }}
            >
              {filteredFaculties.mentor.map((option) => (
                <MenuItem value={option.email}>{option.email}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <Autocomplete
            limitTags={1}
            className={classes.field}
            options={filteredFaculties?.trainer}
            getOptionLabel={(option) => option?.email || ""}
            defaultValue={
              filteredFaculties?.trainer?.length > 0
                ? filteredFaculties.trainer.find(
                    (trainer) => trainer?.email === tempLearner?.trainer
                  )
                : ""
            }
            onChange={(e) => updateLearnerChangeHandler(e, "trainer")}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "disabled",
                }}
                label="Trainer Email"
                variant="outlined"
              />
            )}
          /> */}

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Trainer</InputLabel>
            <Select
              className={classes.field}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tempLearner.trainer}
              label="Trainer"
              onChange={(e) =>
                updateLearnerChangeHandler(e.target.value, "trainer")
              }
              endAdornment={
                <IconButton
                  sx={{
                    visibility: tempLearner.trainer ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setTempLearner({
                      ...tempLearner,
                      trainer: "",
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              }
              sx={{
                "& .MuiSelect-iconOutlined": {
                  display: tempLearner.trainer ? "none" : "",
                },
              }}
            >
              {filteredFaculties.trainer.map((option) => (
                <MenuItem value={option.email}>{option.email}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Apprenticeship standard"
            variant="outlined"
            className={classes.field}
            name="apprenticeshipStandard"
            value={tempLearner.apprenticeshipStandard}
            onChange={(e) => updateLearnerChangeHandler(e)}
            error={learnerValidations.apprenticeshipStandard}
            helperText={
              learnerValidations.apprenticeshipStandard &&
              "This can't be empty."
            }
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Programme Status
            </InputLabel>
            <Select
              name="status"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tempLearner.status}
              label="Programme Status"
              onChange={updateLearnerChangeHandler}
            >
              <MenuItem value="On">On</MenuItem>
              <MenuItem value="Break">Break</MenuItem>
              <MenuItem value="Off">Off</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={tempLearner.emailToMentor}
                onChange={(e) => updateLearnerCheckboxHandler(e)}
                name="emailToMentor"
              />
            }
            label="Email confirmation to mentor"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={tempLearner.emailToCoach}
                onChange={(e) => updateLearnerCheckboxHandler(e)}
                name="emailToCoach"
              />
            }
            label="Email confirmation to coach"
          />
        </FormGroup>
        <div className="drawerFooter editLearnerModal__footer">
          {/* <FormControlLabel
            className="materialSwitchLabel"
            control={
              <Switch
                checked={tempLearner && tempLearner.active}
                onChange={(e) => updateLearnerChangeHandler(e)}
                name="isLearnerActive"
                className="materialSwitchBtn"
              />
            }
            label={tempLearner.active ? "Active" : "Inactive"}
          /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              variant="contained"
              className={classes.button}
              onClick={updateLearner}
            >
              Save
            </Button>

            <Button
              variant="contained"
              className={classes.buttonn}
              onClick={close}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Drawer>
  );
};

export default UpdateLearner;
