// @mui
import { Avatar, Box } from "@mui/material";

export default function NameAvatar({ name }) {
  const getName = () => {
    if (name) {
      let nameArray = name.split(" ");
      if (nameArray.length > 1) {
        return nameArray[0][0] + nameArray[nameArray.length - 1][0];
      } else {
        return nameArray[0][0];
      }
    } else {
      return "";
    }
  };
  console.log(getName());
  return (
    <Avatar
      sx={{ width: 50, height: 50 }}
      style={{
        marginRight: ".5rem",
        marginBottom: ".5rem",
      }}
    >
      {getName()}
    </Avatar>
  );
}
