export const _customBasicPricingPlan = {
  price: "price_1KibUzDIf5MKeP8OmjHPzuq8",
  name: "basic",
  lists: [
    { text: "All essential features", isAvailable: true },
    { text: "2 GB Data Storage", isAvailable: true },
    {
      text: "Organisation Support + 7 Star Training Support (Working Days)",
      isAvailable: true,
    },
    {
      text: "Download reports as CSV (Excel files)",
      isAvailable: false,
    },
    {
      text: "Submit to all learners (Diary Entry)",
      isAvailable: false,
    },
  ],
};

export const _customStandardPricingPlan = {
  price: "price_1KibUzDIf5MKeP8OmjHPzuq8",
  name: "standard",
  lists: [
    { text: "All essential features", isAvailable: true },
    { text: "100 GB Data Storage", isAvailable: true },
    {
      text: "Organisation Support + 7 Star Training Support (Working Days)",
      isAvailable: true,
    },
    {
      text: "Download reports as CSV (Excel files)",
      isAvailable: true,
    },
    {
      text: "Submit to all learners (Diary Entry)",
      isAvailable: false,
    },
  ],
};

export const _customPremiumPricingPlan = {
  price: "price_1KibUzDIf5MKeP8OmjHPzuq8",
  name: "premium",
  lists: [
    { text: "All essential features", isAvailable: true },
    { text: "250 GB Storage", isAvailable: true },
    {
      text: "Organisation Support + 7 Star Training Support (24/7)",
      isAvailable: true,
    },
    {
      text: "Download reports as CSV (Excel files)",
      isAvailable: true,
    },
    {
      text: "Submit to all learners (Diary Entry)",
      isAvailable: true,
    },
  ],
};

export const _customPricingPlan = {
  price: "price_1KibUzDIf5MKeP8OmjHPzuq8",
  name: "custom",
  lists: [
    { text: "Non recuring, pay once", isAvailable: true },
    { text: "Customizable Subscriptions", isAvailable: true },
    { text: "Unlimited Storage", isAvailable: true },
    {
      text: "Organisation Support + 7 Star Training Support (24/7)",
      isAvailable: true,
    },
  ],
};
