import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreActions from "./MoreActions";
import { Box, Card, TableFooter } from "@mui/material";
import Toolbar from "./Toolbar";
import axios from "axios";
import { isEqual } from "lodash";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { useDispatch, useSelector } from "../../redux/store";
import { useEffect } from "react";
import { getLearners, setAllLearners } from "../../redux/slices/learner";

import "./LearnerListNew";

export default function LearnerListNew({ deleteLearner, edit, maxHeight }) {
  const pageRole = window.location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const { learners } = useSelector((state) => state.learner);


  const { data } = React.useContext(AppContext)

  

  let { id } = useParams();

  let maxheightObj = {
    admin: 386,
    manager: 928,
    coach: 928,
    mentor: 928,
    visitor: 928,
    trainer: 928,
  };

  let minheightObj = {
    admin: 386,
    manager: 928,
    coach: 928,
    mentor: 928,
    visitor: 928,
    trainer: 928,
  };

  let viewmaxheight={
    admin: 395,
    manager: 735,
    coach: 735,
    mentor: 735,
    visitor: 735,
    trainer: 735,
  };

  let viewminheight = {
    admin: 395,
    manager: 735,
    coach: 735,
    mentor: 735,
    visitor: 735,
    trainer: 735,
  };
  useEffect(() => {
    dispatch(getLearners(id));
  }, [dispatch]);

  // To Fetch All Learners
  // const searchhLearners = async (query) => {
  //   try {
  //     await axios
  //       .post(
  //         `faculty/learners/${id}`,
  //         { query, targetStatus: "On" },
  //         {
  //           config: { handlerEnabled: true },
  //         }
  //       )
  //       .then((response) => {
  //         dispatch(setAllLearners(response.data.data));
  //       });
  //   } catch (e) {
  //     if (e.response && e.response.data) {
  //       // updateMessage(e.response.data.message);
  //     }
  //   }
  // };

  // console.log(data._id == id, data._id,id  )
  return (
    <Card sx={{ mt: 2 }}>
      <Toolbar />
      <TableContainer
        component={Paper}
        sx={{
          mt: 2,
          maxHeight:  data._id == id ? minheightObj[pageRole] : viewmaxheight[pageRole],
          minHeight: data._id == id  ? maxheightObj[pageRole] : viewminheight[pageRole],
          "&.MuiTableContainer-root": { boxShadow: "none" },
        }}
      >
        <Table
          sx={{
            // maxWidth: 960,
          }}
          stickyHeader 
          aria-label="simple table"
        >
          <TableHead>
            <TableRow className="Admin-Page-right-margin">
              <TableCell align="left">Name</TableCell>
              {/* <TableCell align="left">Email</TableCell> */}
              <TableCell align="left">
                {pageRole === "coach" ? "Employer" : "Coach"}
              </TableCell>
              <TableCell align="left">Programme</TableCell>
              <TableCell align="left">Target</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {learners.length > 0 &&
              learners.map((row) => {
                const {
                  _id,
                  name,
                  status,
                  employerName,
                  coachMentor,
                  targetStatus,
                } = row;
                const coach = coachMentor.find((x) => x.role === "coach");
                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& td, & th":{
                        maxWidth: "90px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {name}
                    </TableCell>
                    {/* <TableCell align="left">{email}</TableCell> */}
                    {pageRole == "coach" ? (
                      <TableCell align="left">{employerName}</TableCell>
                    ) : (
                      <TableCell align="left">
                        {coach ? (
                          pageRole !== "mentor" &&
                          pageRole !== "visitor" &&
                          pageRole !== "trainer" ? (
                            <a
                              href={`${window.location.origin}/coach/${coach._id}`}
                              rel="noreferrer"
                              target="_blank"
                              title={coach.name}
                              style={{ textDecoration: "none" }}
                            >
                              {coach.name}
                            </a>
                          ) : (
                            coach.name
                          )
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                    )}
                    <TableCell align="left">{status}</TableCell>
                    <TableCell align="left">{targetStatus}</TableCell>
                    <TableCell align="left">
                      <MoreActions
                        learner={row}
                        deleteLearner={deleteLearner}
                        edit={edit}
                      ></MoreActions>
                    </TableCell>
                  </TableRow>
                );
              })}
            {learners.length <= 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={6}
                  sx={{
                    padding:
                      pageRole === "admin" || pageRole === "manager"
                        ? "10.275rem 0"
                        : "13.6rem 0",
                    "&.MuiTableCell-root": { border: 0 },
                  }}
                >
                  No matching results are found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
