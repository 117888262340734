import React, { useContext, useEffect } from "react";
import NavBar from "../Header/NavBar";
// import { Footer } from "../Footer/Footer";
import { AppContext } from "../../context/appContext";
import { useHistory } from "react-router-dom";

const Layout = (props) => {
  const { data } = useContext(AppContext);
  const history = useHistory();

  // useEffect(() => {
  //   console.log(data, "app");
  //   if (data && data.tenant.subscription.status != "active") {
  //     console.log("is not valid subsc.");
  //     history.push("/pricing");
  //   }
  // }, [data]);

  return (
    <div>
      <NavBar />
      {props.children}
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
