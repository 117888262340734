import React, { useEffect, useState, useContext } from "react";
import { useDropzone } from "react-dropzone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import { AppContext } from "../../context/appContext";
import { cloneDeep } from "lodash";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  // marginTop: 16,
  // gap: "1.5rem",
  margin: 0 - 10,
};

const thumb = {
  display: "flex",
  borderRadius: 2,
  // marginBottom: 8,
  // marginRight: 8,
  margin: 10,
  width: 150,
  height: "max-content",
  // padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  flexDirection: "row-reverse",
  // minWidth: 0,
  // overflow: "hidden",
  // height: "max-content",
};

const img = {
  display: "block",
  width: "100%",
  height: "auto",
};
const dropBox = {
  flex: "1",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "25px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: " none",
  cursor: "pointer",
};
const icon = {
  cursor: "pointer",
};
const imgTitle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "150px",
  display: "flex",
  justifyContent: "center",
};
const altImgCont = {
  display: "flex",
  fontSize: "12px",
  flexDirection: "column",
  width: 150,
  alignItems: "center",
  textAlign: "center",
};

const UpdateImageDropzone = (props) => {
  const [allImages, setAllImages] = useState([]);
  // data, setData, handleToggle, updateMessage, getUserData
  const { updateMessage } = useContext(AppContext);

  useEffect(() => {
    let images = props.diary.attachments.concat(props.diary.files);
    setAllImages(images);
    // console.log(images, " images var UE", props.diary.files, " props diary");
    // console.log(allImages, "All images State useEffect");
  }, [props.diary.attachments, props.diary.files]);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: "image/*", application/pdf,
    accept:
      "image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/mp4, video/webm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    maxSize: 10485760,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        if (rejectedFiles[0].errors[0].code === "file-invalid-type") {
          updateMessage(
            `${rejectedFiles[0].file.name}'s format not supported.`
          );
        } else {
          updateMessage(`${rejectedFiles[0].file.name} is greater than 10 mb.`);
        }
      }
      // console.log(allImages, "All images State");
      if (acceptedFiles.length <= 5 - allImages.length) {
        let files = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        let newFiles = cloneDeep(props.diary.files).concat(files);
        props.setDiary({
          ...props.diary,
          files: newFiles,
        });
      } else {
        updateMessage(`You can upload maximum of 5 files to single diary.`);
      }
    },
  });

  const deleteFile = (i) => {
    // console.log(i);
    // let updatedImages = props.diary.attachments.slice();
    // updatedImages.splice(i, 1);
    // props.setDiary({ ...props.diary, attachments: updatedImages });
    // ------------ New code below ------------------------
    if (allImages[i] instanceof File) {
      // console.log("Deleted file is new uploaded");
      let updatedImages = cloneDeep(props.diary.files);
      let fileIndex = props.diary.attachments.length - i;
      updatedImages.splice(Math.abs(fileIndex), 1);
      props.setDiary({ ...props.diary, files: updatedImages });
    } else {
      // console.log("Deleted already uploaded file");
      let updatedImages = cloneDeep(props.diary.attachments);
      updatedImages.splice(i, 1);
      props.setDiary({ ...props.diary, attachments: updatedImages });
    }
  };

  const thumbs = allImages.map((file, index) => {
    return (
      <div style={thumb} key={index}>
        <div style={thumbInner}>
          <HighlightOffTwoToneIcon
            onClick={() => deleteFile(index)}
            style={icon}
          />
          {file.type.includes("image") ? (
            <div>
              <img src={file.preview} alt={file.name} style={img} />
              <span style={imgTitle}>{file.name}</span>
            </div>
          ) : (
            <div style={altImgCont}>
              <img
                src="https://static.thenounproject.com/png/2401719-200.png"
                alt={file.name}
                style={img}
              />
              <span style={imgTitle}>{file.name}</span>
            </div>
          )}
        </div>
      </div>
    );
  });

  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     props.diary.files.forEach((file) =>
  //       URL.revokeObjectURL(file.preview)
  //     );
  //   },
  //   [props.diary.files]
  // );

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <h3>Add Attachment</h3>
        <p style={dropBox}>
          Drag 'n' drop some files here, or click to select files
          <br />
          (Supported formats include .png, .jpeg, .xslx. docx, .mp4, .webm)
        </p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
};
export default UpdateImageDropzone;
