import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FileViewer from "react-file-viewer";
import "./styles.css";

// const file = "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg";
// const type = "jpg";

// const onError = e => {
//   console.log(e, "error in file-viewer");
// };

// const props = {
//   allowFullScreen: true,
//   src: "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
// };

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: "95vw",
  maxHeight: "95vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

export default function AttachmentPreview(props) {
  return (
    <Modal
      open="true"
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <FileViewer
          fileType={props.type}
          filePath={props.file}
          onError={props.onError}
        />
      </Box>
    </Modal>
  );
}
