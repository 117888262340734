import * as React from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";

export default function MoreActions({ learner, edit, deleteLearner }) {
  const pageRole = window.location.pathname.split("/")[1];
  const { data, handleToggle, updateMessage } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const visitLearnerPage = (id) => {
    window.open(`${window.origin}/learner/${id}`);
  };

  // To Download The Learner Report
  const downloadLearnerReport = async (id) => {
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-OTJ-report/${id}`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${learner.name}'s report`);
          link.href = fileURL;

          // Auto click the link
          link.click();

          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  // To Download The Learner Evidence Report
  const downloadEvidenceReport = async (id) => {
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-evidence-report/${id}`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${learner.name}'s report`);
          link.href = fileURL;

          // Auto click the link
          link.click();

          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          key={1 * Math.random()}
          onClick={() => {
            handleClose();
            visitLearnerPage(learner._id);
          }}
        >
          <PersonOutlineSharpIcon fontSize="small" />
          &nbsp;&nbsp;Profile
        </MenuItem>
        <MenuItem
          key={2 * Math.random()}
          onClick={() => {
            handleClose();
            downloadLearnerReport(learner._id);
          }}
        >
          <GetAppIcon fontSize="small" />
          &nbsp;&nbsp;Download Learner Report
        </MenuItem>
        <MenuItem
          key={2 * Math.random()}
          onClick={() => {
            handleClose();
            downloadEvidenceReport(learner._id);
          }}
        >
          <GetAppIcon fontSize="small" />
          &nbsp;&nbsp;Download OTJ Evidence Collection Report
        </MenuItem>
        {pageRole === "admin" && (
          <MenuItem
            key={3 * Math.random()}
            onClick={() => {
              handleClose();
              edit(learner);
            }}
          >
            <EditIcon fontSize="small" />
            &nbsp;&nbsp;Edit
          </MenuItem>
        )}

        {pageRole === "admin" && (
          <MenuItem
            key={4 * Math.random()}
            onClick={() => {
              handleClose();
              deleteLearner(learner);
            }}
          >
            <DeleteOutlineIcon fontSize="small" />
            &nbsp;&nbsp;Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
