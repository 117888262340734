import {
  useState,
  useEffect,
  useContext,
  useCallback,
  Fragment,
  useLayoutEffect,
} from "react";
import "./Learner.css";
import ApprentiseshipStatusModal from "../../components/ApprentiseshipStatusModal/ApprentiseshipStatusModal";
import TimeAgo from "react-timeago";
import moment from "moment";
import { makeStyles } from "@mui/styles";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Menu,
  List,
  ListItem,
  Tooltip,
  Skeleton,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrackChangesSharpIcon from "@mui/icons-material/TrackChangesSharp";
import GetAppIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import StopIcon from "@mui/icons-material/Stop";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import DatePicker from "@mui/lab/DatePicker";
//
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import axios from "axios";
import enLocale from "date-fns/locale/en-GB";
import { useParams } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { debounce, cloneDeep } from "lodash";
import { getDateFromUkString, getHours } from "../../utils/functions";

import TimeInput from "../../components/TimeInput/TimeInput";
import ImageDropzone from "../../components/ImageDropzone/ImageDropzone";
import RefreshButton from "../../components/RefreshButton/Refresh";
import ProfileContainer from "../../components/ProfileContainer/ProfileContainer";
import InputAdornment from "@mui/material/InputAdornment";
import UpdateDiaryModal from "../../components/UpdateDiaryModal/UpdateDiaryModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import AttachmentPreview from "../../components/AttachmentPreview/AttachmentPreview";
import { RestrictFeature } from "../../components/RestrictFeature/RestrictFeature";
import NameAvatar from "../../components/NameAvatar";
// import Skeleton from "@mui/lab/Skeleton";

const plannedOTJMessage = {
  On: "On target",
  Below: "To be complete",
  Over: "Over target",
};

const useStyles = makeStyles(() => ({
  noDataImage: { display: "flex", justifyContent: "center" },
  divStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divStylee: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
  },
  field: {
    marginBottom: 20,
  },
  formStylee: {
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    justifyContent: "space-between",
  },
  accordianBox: {
    paddingTop: 15,
    borderRadius: 10,
    paddingBottom: 15,
    marginTop: 20,
    marginBottom: 20,
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  filterBoxStyle: {
    marginTop: 20,
    padding: 20,
    borderRadius: 10,
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.10)",
    border: "1px solid lightGray",
    display: "flex",
    justifyContent: "flex-end",
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 10,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
  },
  profileCardText: {
    fontSize: 16,
    color: "white",
  },
  profileCardText1: {
    fontSize: 12,
    color: "white",
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  text2: {
    marginTop: "30px",
    // marginLeft: "25px",
  },
  text1Sub: {
    fontSize: "1.1rem",
  },
  buttonn: {
    background: "#939393",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  heading: {
    fontSize: 15,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: 10,
    alignSelf: "center",
  },
  accordianGrid: {
    marginBottom: 20,
  },
  accordianText: {
    fontSize: "12px",
  },
  accDiv: {
    display: "flex",
    // gap: "1rem",
    overflowY: "auto",
    // margin: "0 -.5rem",
  },
  boldFont: {
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 900,
  },
  buttons: {
    display: "flex",
    // justifyContent: "space-evenly",
    justifyContent: "end",
  },
  formControl: {
    minWidth: 220,
  },
  accordianSummaryContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  root: {
    maxWidth: "100%",
  },
  buttonCont: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  mr: {
    marginRight: "2px",
  },
  ml: {
    marginLeft: "2px",
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
  },
  accordianImg: {
    maxHeight: 150,
    maxWidth: 150,
  },
  altImg: {
    height: 130,
    width: 130,
  },
  altImgCont: {
    display: "flex",
    fontSize: "12px",
    flexDirection: "column",
    width: 150,
    alignItems: "center",
    textAlign: "center",
  },
  imgPreviewCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: ".5rem",
  },
  imgCont: {
    display: "flex",
    // gap: ".5rem",
    justifyContent: "center",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    marginTop: 20,
  },
}));

export const LearnerPage = () => {
  moment.locale("en-GB");
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const classes = useStyles();
  const [userData, setUserData] = useState();
  const [entries, setEntries] = useState([]);
  const [preview, setPreview] = useState({
    show: false,
    file: "",
    type: "",
  });
  const [apprentiseshipStatusModal, setApprentiseshipStatusModal] =
    useState(false);

  // to get id of the learner
  let { id } = useParams();
  const isDesktop = window.innerWidth > 899 ? true : false;

  // initial state variable
  let initialState = {
    diaryState: {
      title: "",
      description: "",
      requestAcknowledgement: false,
      submitToAllLearners: false,
      attachments: [],
    },
    activityState: {
      type: "Not OTJ Learning Activity",
      startDate: new Date(),
      endDate: new Date(),
      hours: "",
      minutes: "",
    },
    standardsState: {
      duties: "",
      knowledge: "",
      skills: "",
      behaviour: "",
      others: "",
    },
    errorState: {
      titleError: false,
      descriptionError: false,
      hoursError: false,
      minutesError: false,
    },
  };
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState();
  const [diaryModal, setDiaryModal] = useState(false);
  const [singleEntry, setSingleEntry] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState({
    type: "",
    weekName: "",
    query: "",
  });

  const [diary, setDiary] = useState(initialState.diaryState);
  const [learningActivity, setLearningActivity] = useState(
    initialState.activityState
  );
  const [occupationalStandards, setOccupationalStandards] = useState(
    initialState.standardsState
  );
  const [error, setError] = useState(initialState.errorState);

  //occupationa Standards Change Handler
  const occupationalStandardsChangeHandler = (e) => {
    const value = e.target.value;
    setOccupationalStandards({
      ...occupationalStandards,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    getData(filter);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = async (e) => {
    let obj = { ...filter, [e.target.name]: e.target.value };
    setFilter(obj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, obj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const entryFilterHandler = async () => {
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filter, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const clearHandler = async () => {
    let filterObj = {
      type: "",
      weekName: "",
      query: "",
    };
    setFilter(filterObj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  // To Apply Search Filter
  const entryFilterSearchHitApi = async (e) => {
    let newFilters = cloneDeep(filter);
    newFilters.query = e;
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, newFilters, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  // To Apply Search With Debounce
  const handler = useCallback(debounce(entryFilterSearchHitApi, 1000), [
    filter,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Set Search Filter Value
  const entryFilterSearchHandler = async (e) => {
    let filterObj = { ...filter, [e.target.name]: e.target.value };
    setFilter(filterObj);
    handler(e.target.value);
  };

  //to remove filter from chips
  const removeFilterType = async () => {
    let filterObj = {
      ...filter,
      type: "",
    };
    setFilter(filterObj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const removeFilterMonth = async () => {
    let filterObj = {
      ...filter,
      weekName: "",
    };
    setFilter(filterObj);
    try {
      handleToggle(true);
      await axios
        .post(`learner/entries/${id}`, filterObj, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
          setAnchorEl(null);
          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);
      }
    }
  };

  const deleteEntry = async () => {
    try {
      handleToggle(true);
      await axios
        .delete(`learner/entry/${entryToDelete}`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          getData(filter);
          handleToggle(false);
          updateMessage(response.data.message);
          setConfirmationModal(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        setConfirmationModal(false);
        handleToggle(false);

        if (e?.response?.status === 400) {
          setApprentiseshipStatusModal(true);
        }
      }
    }
  };

  const handleStartDateChange = (date) => {
    setLearningActivity({ ...learningActivity, startDate: date });
  };

  const handleEndDateChange = (date) => {
    setLearningActivity({ ...learningActivity, endDate: date });
  };

  const learningActivityChangeHandler = (e) => {
    setLearningActivity({
      ...learningActivity,
      type: e.target.value,
    });
  };

  const diaryChangeHandler = (e) => {
    const value = e.target.value;
    setError({ ...error, [e.target.name + "Error"]: value ? false : true });
    if (
      e.target.name === "requestAcknowledgement" ||
      e.target.name === "submitToAllLearners"
    ) {
      setDiary({
        ...diary,
        [e.target.name]: e.target.checked,
      });
    } else {
      setDiary({
        ...diary,
        [e.target.name]: value,
      });
    }
  };

  const validateForm = () => {
    let titleError = false;
    let descriptionError = false;
    let hoursError = false;
    let minutesError = false;

    if (diary.title === "") {
      titleError = "Please enter the title";
    }
    if (diary.description === "") {
      descriptionError = "Please enter the description";
    }
    if (learningActivity.type !== "Not OTJ Learning Activity") {
      if (learningActivity.hours === "") {
        hoursError = "Please enter the hours";
      }
      if (learningActivity.minutes === "") {
        minutesError = "Please enter the minutes";
      }
    }
    if (titleError || descriptionError || hoursError || minutesError) {
      setError({
        ...error,
        titleError,
        descriptionError,
        hoursError,
        minutesError,
      });
      return false;
    }
    return true;
  };

  //submit diary entry form
  const submitHandler = async () => {
    const isValid = validateForm();
    if (isValid) {
      let { attachments } = diary;
      let formData = new FormData();
      for (let i = 0; i < attachments.length; i++) {
        formData.append("files", attachments[i]);
      }
      let data = {
        title: diary.title,
        description: diary.description,
        acknowledgement: diary.requestAcknowledgement,
        submitToAllLearners: diary.submitToAllLearners,
        standards: occupationalStandards,
        activity: learningActivity,
        createdForId: id,
      };
      formData.append("data", JSON.stringify(data));
      try {
        handleToggle(true);
        await axios
          .post("learner/entry", formData, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            diary.attachments.forEach((file) =>
              URL.revokeObjectURL(file.preview)
            );
            setDiary(initialState.diaryState);
            setLearningActivity(initialState.activityState);
            setOccupationalStandards(initialState.standardsState);
            setError(initialState.errorState);
            handleToggle(false);
            updateMessage(response.data.message);
            let filter = {
              type: "",
              weekName: "",
            };
            setFilter(filter);
            getData(filter);
          });
      } catch (e) {
        if (e.response && e.response.data) {
          handleToggle(false);

          if (e?.response?.status === 400) {
            setApprentiseshipStatusModal(true);
          }
        }
      }
    } else {
      updateMessage("Please Fill All The Required Fields!!");
    }
  };

  //reset diary entry form
  const resetHandler = () => {
    setDiary(initialState.diaryState);
    setLearningActivity(initialState.activityState);
    setOccupationalStandards(initialState.standardsState);
    setError(initialState.errorState);
  };
  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //download Report
  const downloadHandler = async () => {
    try {
      handleToggle(true);
      await axios
        .get(`learner/generate-OTJ-report/${userData._id}`, {
          config: { handlerEnabled: true },
          responseType: "blob",
        })
        .then((response) => {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          // window.open(fileURL, "_blank");

          // Code for download
          let link = document.createElement("a");
          document.documentElement.append(link);

          link.setAttribute("download", `${userData.name}'s report`);
          link.href = fileURL;

          // Auto click the link
          link.click();

          handleToggle(false);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        handleToggle(false);

        if (e?.response?.status === 400) {
          setApprentiseshipStatusModal(true);
        }
      }
    }
  };

  const learningActivityFilter = [
    "Classroom Training",
    "Training at Work",
    "Learning Support",
    "Lectures",
    "Webinars/Online Learning",
    "Shadowing",
    "Mentoring",
    "Writing Assignments",
    "Reading/Theory Learning",
    "Manufacturer Training",
    "Role Playing",
    "Simulation Exercises",
    "Industry Visits",
    "Participation in Competitions",
    "Other OTJ Learning Activity",
    "Not OTJ Learning Activity",
  ];

  const getHoursMinutesFormated = (seconds) => {
    let hoursMinutes = getHours(seconds);
    return hoursMinutes.hours + ":" + hoursMinutes.minutes;
  };

  const getTextColor = (status) => {
    if (status === "On") {
      return { color: "#7CBD1A" };
    } else if (status === "Below") {
      return { color: "#BD1A41" };
    } else {
      return { color: "#0000FF" };
    }
  };

  const openDiaryEditModal = (e, entry) => {
    e.stopPropagation();
    setDiaryModal(true);
    setSingleEntry(entry);
  };

  const closeDiaryEditModal = (e) => {
    setDiaryModal(false);
  };

  const getData = async (filter) => {
    try {
      await axios
        .get(`learner/${id}`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setUserData(response.data.data);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
    try {
      await axios
        .post(`learner/entries/${id}`, filter, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setEntries(response.data.data);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  const acceptAcknowledgement = async (id) => {
    try {
      handleToggle(true);
      await axios
        .get(`/learner/accept-ack/${id}`, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          // props.refreshData();
          getData(filter);
          handleToggle(false);
          updateMessage(response.data.message);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        console.error(e);
      }
    }
  };

  const openConfirmationModal = (e, entryId) => {
    e.stopPropagation();
    setEntryToDelete(entryId);
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const filePreviewError = () => {
    setPreview({
      show: false,
    });
    updateMessage("Something went wrong while displaying file.");
  };

  var isMobileDevice = false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    isMobileDevice = true;
  }

  // To Fetch All Data When User Back TYo Page After Visiting Another Tab
  const onVisibilityChange = async () => {
    let hidden;
    // let visibilityChange;

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      // visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      // visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      // visibilityChange = "webkitvisibilitychange";
    }

    if (!document[hidden]) {
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const diaryRecords = () => (
    <Container maxWidth="xl">
      <Fragment>
        {data ? (
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.boxStyle}>
                <div className="headerContainer">
                  <div className={classes.headingContainer}>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learning Diary Records
                    </Typography>
                    <span
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0 -.5rem",
                      }}
                    >
                      {filter.type && (
                        <Chip
                          label={filter.type}
                          onDelete={removeFilterType}
                          size="small"
                          style={{ margin: ".5rem" }}
                        />
                      )}
                      {filter.weekName && (
                        <Chip
                          label={filter.weekName}
                          onDelete={removeFilterMonth}
                          size="small"
                          style={{ margin: ".5rem" }}
                        />
                      )}
                    </span>
                  </div>
                  <div className="filterContainer">
                    <TextField
                      label="Search"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {filter.query !== "" && (
                              <CancelIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  let customEvent = {
                                    target: {
                                      name: "query",
                                      value: "",
                                    },
                                  };
                                  entryFilterSearchHandler(customEvent);
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      name="query"
                      // onKeyDown={(e) => handleKeyDown(e)}
                      value={filter.query}
                      onChange={entryFilterSearchHandler}
                    />
                    <Tooltip arrow title="Filter" placement="right">
                      <FilterListIcon
                        onClick={handleMenuOpen}
                        className={classes.icons}
                      />
                    </Tooltip>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <List
                        component="nav"
                        className={classes.root}
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItem className={classes.buttonCont}>
                          <Button
                            className={`${classes.mr} ${classes.button}`}
                            onClick={clearHandler}
                          >
                            Clear
                          </Button>
                          <Button
                            className={`${classes.ml} ${classes.button}`}
                            onClick={entryFilterHandler}
                          >
                            Filter
                          </Button>
                        </ListItem>
                        <ListItem button>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                              Filter Learner Activity
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Filter Learner Activity"
                              fullWidth
                              value={filter.type}
                              onChange={handleFilterChange}
                              name="type"
                            >
                              {learningActivityFilter.map((activity, index) => {
                                return (
                                  <MenuItem key={index} value={activity}>
                                    {activity}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </ListItem>
                        <ListItem button>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                              Filter By Week
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Filter By Week"
                              fullWidth
                              name="weekName"
                              value={filter.weekName}
                              onChange={handleFilterChange}
                            >
                              {userData &&
                                userData.monthlyHourMinutes.map(
                                  (month, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={month.weekName}
                                      >
                                        {month.weekName} (
                                        {getHoursMinutesFormated(
                                          month.targetSeconds
                                        )}
                                        )
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                          </FormControl>
                        </ListItem>
                      </List>
                    </Menu>
                  </div>
                </div>

                {entries && entries.length > 0 ? (
                  entries.map((entry, index) => {
                    return (
                      <Accordion
                        key={index}
                        className={classes.accordianBox}
                        // expanded={expanded === 'panel1'}
                        // onChange={handleChange('panel1')}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div
                            className={`accordianSummaryContainer ${classes.accordianSummaryContainer}`}
                          >
                            <div
                              className="ldr-acc-head"
                              style={{ display: "flex" }}
                            >
                              {entry?.createdById?.imageUrl ? (
                                <Avatar
                                  alt={entry?.createdById?.name}
                                  src={
                                    entry?.createdById?.imageUrl
                                      ? entry.createdById.imageUrl
                                      : "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
                                  }
                                  sx={{ width: 50, height: 50 }}
                                  style={{
                                    marginRight: ".5rem",
                                    marginBottom: ".5rem",
                                  }}
                                />
                              ) : (
                                <NameAvatar name={entry?.createdById?.name} />
                              )}
                              <div style={{ alignSelf: "center" }}>
                                <Typography style={{ fontSize: 15 }}>
                                  {entry.title}
                                </Typography>
                                <div
                                  className="accHeadTime"
                                  style={{ display: "flex" }}
                                >
                                  <Typography
                                    className={classes.secondaryHeading}
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <TimeAgo
                                      date={entry.createdAt}
                                      // title={moment(
                                      //   entry.activity.startDate
                                      // ).format("DD-MMM-YY HH:mm")}
                                      title={moment(entry.createdAt).format(
                                        "DD-MMM-YY HH:mm"
                                      )}
                                    />
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: 10,
                                      alignSelf: "center",
                                      color: "#6B3AFF",
                                    }}
                                  >
                                    {entry.isEdited ? (
                                      <Tooltip
                                        title={
                                          <TimeAgo date={entry.updatedAt} />
                                        }
                                        arrow
                                        placement="right"
                                      >
                                        <span>Edited</span>
                                      </Tooltip>
                                    ) : null}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            {data &&
                              data?._id === entry?.createdById?._id &&
                              entry.createdById._id &&
                              data.role != "visitor" && (
                                <div className="ldr-acc-head-icons">
                                  <IconButton
                                    onClick={(e) =>
                                      openDiaryEditModal(e, entry)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={(e) =>
                                      openConfirmationModal(e, entry._id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              )}
                          </div>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Grid container className={classes.cardStyle}>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                Title
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.title}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Description
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Activity OFF-The-Job
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.activity.type}
                              </Typography>
                            </Grid>
                            {/* {entry.activity.type !=
                            "Not OTJ Learning Activity" && (
                            <> */}
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Start date & time
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {moment(entry.activity.startDate).format(
                                  "LLLL"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                End date & time
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {moment(entry.activity.endDate).format(
                                  // "DD-MMM-YY"
                                  "LLLL"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Duration
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.accordianText}>
                                {entry.activity.hours ? (
                                  <span>
                                    {entry.activity.hours}
                                    {entry.activity.hours > 1
                                      ? " Hours "
                                      : " Hour "}
                                  </span>
                                ) : (
                                  "0 Hour"
                                )}
                                {entry.activity.minutes ? (
                                  <span>
                                    {entry.activity.minutes}
                                    {entry.activity.minutes > 1
                                      ? " Minutes"
                                      : " Minute"}
                                  </span>
                                ) : (
                                  " 0 Minute"
                                )}
                              </Typography>
                            </Grid>
                            {/* </>
                          )} */}
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                Occupational standards
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <Typography className={classes.boldFont}>
                                Duties:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.duties === ""
                                  ? "N/A"
                                  : entry.standards.duties}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Knowledge:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.knowledge === ""
                                  ? "N/A"
                                  : entry.standards.knowledge}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Skills:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.skills === ""
                                  ? "N/A"
                                  : entry.standards.skills}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Behaviour:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.behaviour === ""
                                  ? "N/A"
                                  : entry.standards.behaviour}
                              </Typography>
                              <Typography className={classes.boldFont}>
                                Others:
                              </Typography>
                              <Typography className={classes.accordianText}>
                                {entry.standards.others === ""
                                  ? "N/A"
                                  : entry.standards.others}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography className={classes.accordianText}>
                                File Attachments
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={8}
                              className={classes.accordianGrid}
                            >
                              <div className={`accDiv ${classes.accDiv}`}>
                                {entry.attachments.length > 0 ? (
                                  entry.attachments.map((file, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className={classes.imgPreviewCont}
                                      >
                                        {file.type.includes("image") ? (
                                          <img
                                            src={
                                              isMobileDevice
                                                ? file.mobile
                                                : file.desktop
                                            }
                                            alt="Img here"
                                            className={classes.accordianImg}
                                          />
                                        ) : (
                                          <div className={classes.altImgCont}>
                                            <img
                                              src="https://res.cloudinary.com/my-7startraining/image/upload/v1662025613/586281-200_q1jdbq.png"
                                              alt={file.name}
                                              className={classes.altImg}
                                            />
                                            <span
                                              style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                width: "150px",
                                              }}
                                            >
                                              {file.name}
                                            </span>
                                          </div>
                                        )}
                                        {/* <a href="https://res.cloudinary.com/my-7startraining/image/upload/fl_attachment/v1633429664/7-star-training/1633429661632.png">Download</a> */}

                                        <span className={classes.imgCont}>
                                          <a href={file.preview}>
                                            <IconButton
                                              style={{ marginRight: ".5rem" }}
                                            >
                                              <DownloadIcon />
                                            </IconButton>
                                          </a>
                                          {file.type !=
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                                            file.extension != "xlsx" && (
                                              <span>
                                                <IconButton
                                                  onClick={() => {
                                                    setPreview({
                                                      show: true,
                                                      file: file.preview,
                                                      type: file.extension,
                                                    });
                                                  }}
                                                >
                                                  <VisibilityIcon />
                                                </IconButton>
                                              </span>
                                            )}
                                        </span>
                                      </span>
                                    );
                                  })
                                ) : (
                                  <Typography className={classes.accordianText}>
                                    No Files Added!
                                  </Typography>
                                )}
                              </div>
                            </Grid>
                            {data &&
                              (data.role === "learner" ||
                                data.role === "coach") &&
                              entry.acknowledgementStatus && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    className={classes.accordianGrid}
                                  >
                                    <Typography
                                      className={classes.accordianText}
                                    >
                                      Acknowledgement
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={8}
                                    className={classes.accordianGrid}
                                  >
                                    <Typography
                                      className={classes.accordianText}
                                    >
                                      {data?._id === entry?.createdById?._id &&
                                      entry.createdById._id ? (
                                        `Acknowledgement ${entry.acknowledgementStatus}`
                                      ) : (
                                        <span>
                                          {entry.acknowledgementStatus ===
                                          "accepted" ? (
                                            "Acknowledgement accepted"
                                          ) : (
                                            <Button
                                              onClick={() =>
                                                acceptAcknowledgement(entry._id)
                                              }
                                              variant="contained"
                                              className={classes.button}
                                            >
                                              <Typography variant="h8">
                                                Accept Acknowledgement
                                              </Typography>
                                            </Button>
                                          )}
                                        </span>
                                      )}
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className={classes.noDataImage}>
                    <img src="/no-data-found.png" alt="" />
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <div className={classes.skeleton}>
            <Skeleton variant="text" height={50} />
            <Skeleton variant="rect" height={295} />
          </div>
        )}
      </Fragment>

      {!isDesktop && data && data._id === id && (
        <Fragment>
          {userData ? (
            <Box className={classes.cardStyle}>
              <Card>
                <CardContent className="card-content">
                  <Typography variant="h6" gutterBottom>
                    Help & Support
                  </Typography>
                  <Typography variant="body2">
                    Feel free to contact your coach to get training on how to
                    use the web app.
                    <br />
                    <br />
                    If you encounter any technical issues, you should report
                    them to your coach, training provider or employer.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <div className={classes.skeleton}>
              <Skeleton variant="text" height={45} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" width="70%" />
              <Skeleton variant="text" width="70%" height={45} />
            </div>
          )}
        </Fragment>
      )}
    </Container>
  );
  const precedenceLevel = () => {
    switch (userData && userData.status) {
      case "On":
        return userData.progress.apprenticeshipMessage;
        break;
      case "Off":
        return "Learner Withdrawn";
        break;

      case "Completed":
        return "Learner Has Completed";
        // userData.progress.apprenticeshipMessage !== "Practical Period In Progress"
        break;
      default:
        // On Break
        return "Learner On Break In Learning";
    }
  };

  return (
    <Fragment>
      <Container
        component="main"
        maxWidth="xl"
        className="pd-bottom-40"
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Container maxWidth="xl">
          <Box>
            {!isDesktop && (
              <ProfileContainer role="learner" userData={userData} />
            )}
          </Box>
        </Container>

        <Fragment>
          {data ? (
            <Container maxWidth="xl">
              {/* {userData &&
                userData.progress.apprenticeshipMessage !==
                  "Practical Period In Progress" && (
                  <Alert severity="warning" className="mar-top-20">
                    {userData.progress.apprenticeshipMessage}
                  </Alert>
                )} */}
              {userData && (
                <Alert severity="info" className="mar-top-20">
                  {/* {userData.progress.apprenticeshipMessage} */}
                  {precedenceLevel()}
                </Alert>
              )}

              <Box className={classes.boxStyle}>
                <div className="topSection totalPerformance">
                  <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
                    Total Performance
                  </Typography>
                  <div className="topButtons">
                    <RefreshButton />
                    <Button
                      onClick={downloadHandler}
                      className={classes.button}
                      startIcon={<GetAppIcon />}
                    >
                      Download
                    </Button>
                  </div>
                </div>

                {userData && (
                  <Grid container className="learnerPerformanceBox">
                    <Grid item xs={12} md={3}>
                      <Fragment>
                        <Typography
                          variant="h5"
                          component="h3"
                          sx={{ flexGrow: 1 }}
                        >
                          {
                            userData.monthlyHourMinutes[
                              userData.progress.weekNumber - 1
                            ].weekName
                          }{" "}
                          Target
                          {/* <span className="performanceUnit">hr.min</span> */}
                        </Typography>
                        <Typography component="p" variant="h4">
                          {getHours(userData.progress.currentTarget).hours}
                          <span style={{ fontSize: "22px" }}>
                            hr {" . "}
                          </span>{" "}
                          {getHours(userData.progress.currentTarget).minutes}
                          <span style={{ fontSize: "22px" }}>min</span>
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          <StopIcon
                            style={{ color: "#5335CA" }}
                            className={classes.qIcon}
                          />
                          {/* <span style={{ color: "#5335CA" }}>
                            {getHours(userData.totalSeconds).hours}
                            <span style={{ fontSize: "10px" }}>hr{" . "}</span>
                            {getHours(userData.totalSeconds).minutes}
                            <span style={{ fontSize: "10px" }}>min</span>{" "}
                          </span> */}
                          <span style={{ color: "#5335CA" }}>
                            {
                              userData.monthlyHourMinutes[
                                userData.progress.weekNumber - 1
                              ].startDate
                            }
                            <span style={{ fontSize: "10px" }}>{" - "}</span>
                            {
                              userData.monthlyHourMinutes[
                                userData.progress.weekNumber - 1
                              ].endDate
                            }
                            {/* <span style={{ fontSize: "10px" }}>min</span>{" "} */}
                          </span>
                          {/* {userData.durationInMonths} month(s) target */}
                        </Typography>
                      </Fragment>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Fragment>
                        <Typography
                          variant="h5"
                          component="h3"
                          sx={{ flexGrow: 1 }}
                        >
                          {
                            userData.monthlyHourMinutes[
                              userData.progress.weekNumber - 1
                            ].weekName
                          }{" "}
                          Completed{" "}
                          {/* <span className="performanceUnit">hr.min</span> */}
                        </Typography>
                        <Typography component="p" variant="h4">
                          {getHours(userData.progress.cureentProgress).hours}
                          <span style={{ fontSize: "22px" }}>hr{" . "}</span>
                          {getHours(userData.progress.cureentProgress).minutes}
                          <span style={{ fontSize: "22px" }}>min</span>
                        </Typography>

                        <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          {userData.targetStatus === "On" ? (
                            <TrackChangesSharpIcon
                              style={{ color: "#7CBD1A" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Below" ? (
                            <ArrowDropDownIcon
                              style={{ color: "#BD1A41" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Over" ? (
                            <ArrowDropUpIcon
                              style={{ color: "#0000FF" }}
                              className={classes.qIcon}
                            />
                          ) : null}
                          <span style={getTextColor(userData.targetStatus)}>
                            {
                              getHours(
                                userData.progress.differenceBetweenTarget
                              ).hours
                            }
                            hr{" . "}
                            {
                              getHours(
                                userData.progress.differenceBetweenTarget
                              ).minutes
                            }
                            min{" "}
                          </span>
                          {userData.targetStatus} target
                        </Typography>
                      </Fragment>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Fragment>
                        <Typography
                          variant="h5"
                          component="h3"
                          sx={{ flexGrow: 1 }}
                        >
                          Planned OTJ Training{" "}
                          {/* <span className="performanceUnit">hr.min</span> */}
                        </Typography>

                        <Typography component="p" variant="h4">
                          {getHours(userData.progress.totalTarget).hours}
                          <span style={{ fontSize: "22px" }}>
                            hr {" . "}
                          </span>{" "}
                          {getHours(userData.progress.totalTarget).minutes}
                          <span style={{ fontSize: "22px" }}>min</span>
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          {/* {userData.targetStatus === "On" ? (
                            <TrackChangesSharpIcon
                              style={{ height: "18px", color: "#7CBD1A" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Below" ? (
                            <ArrowDropDownIcon
                              style={{ height: "18px", color: "#BD1A41" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Over" ? (
                            <ArrowDropUpIcon
                              style={{ height: "18px", color: "#0000FF" }}
                              className={classes.qIcon}
                            />
                          ) : null} */}
                          {/* <span style={getTextColor(userData.targetStatus)}>
                            {
                              userData.progress
                                .differenceBetweenTargetPercentage
                            }
                            %{" "}
                          </span> */}
                          {userData.targetStatus === "On" ? (
                            <TrackChangesSharpIcon
                              style={{ color: "#7CBD1A" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Below" ? (
                            <ArrowDropDownIcon
                              style={{ color: "#BD1A41" }}
                              className={classes.qIcon}
                            />
                          ) : userData.targetStatus === "Over" ? (
                            <ArrowDropUpIcon
                              style={{ color: "#0000FF" }}
                              className={classes.qIcon}
                            />
                          ) : null}
                          <span style={getTextColor(userData.targetStatus)}>
                            {
                              getHours(
                                userData.progress.totalTarget -
                                  userData.progress.cureentProgress
                              ).hours
                            }
                            hr{" . "}
                            {
                              getHours(
                                userData.progress.totalTarget -
                                  userData.progress.cureentProgress
                              ).minutes
                            }
                            min{" "}
                          </span>
                          {/* To be completed */}
                          {/* {plannedOTJMessage[userData.targetStatus]} */}
                          Total Difference
                        </Typography>
                      </Fragment>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Fragment>
                        <Typography
                          variant="h5"
                          component="h3"
                          sx={{ flexGrow: 1 }}
                        >
                          Practical Period Weeks{" "}
                          {/* <span className="performanceUnit">%</span> */}
                        </Typography>
                        <Typography component="p" variant="h4">
                          {userData.monthlyHourMinutes.length}
                        </Typography>

                        <Typography
                          color="text.secondary"
                          sx={{ flex: 1 }}
                          className={classes.typoText1}
                        >
                          <StopIcon
                            style={{ color: "#5335CA" }}
                            className={classes.qIcon}
                          />
                          {/* <span style={{ color: "#5335CA" }}>
                            {getHours(userData.totalSeconds).hours}
                            <span style={{ fontSize: "10px" }}>hr{" . "}</span>
                            {getHours(userData.totalSeconds).minutes}
                            <span style={{ fontSize: "10px" }}>min</span>{" "}
                          </span> */}
                          <span style={{ color: "#5335CA" }}>
                            {/* {new Date(
                              parseInt(userData.startDate)
                            ).toDateString()} */}
                            {userData.startDate}
                            <span style={{ fontSize: "10px" }}>{" - "}</span>
                            {/* {new Date(
                              parseInt(userData.endDate)
                            ).toDateString()} */}
                            {userData.endDate}
                            {/* <span style={{ fontSize: "10px" }}>min</span>{" "} */}
                          </span>
                          {/* {userData.durationInMonths} month(s) target */}
                        </Typography>
                      </Fragment>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Container>
          ) : (
            <div className={classes.skeleton}>
              <Skeleton variant="text" height={45} />
              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "0 -10px" }}
              >
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
              </div>
            </div>
          )}
        </Fragment>

        <Grid container className="mainSection">
          <Grid item xs={12} md={8.5}>
            <Fragment>
              {data ? (
                <Fragment>
                  {data && data.role !== "visitor" ? (
                    <Container maxWidth="xl" style={{ height: "100%" }}>
                      <Box style={{ height: "100%" }}>
                        <Grid container style={{ height: "100%" }}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.boxStyle}
                          >
                            <div className={`listHeader ${classes.divStyle}`}>
                              <Typography
                                variant="h6"
                                component="h3"
                                sx={{ flexGrow: 1 }}
                              >
                                Create new diary
                              </Typography>
                            </div>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              className={classes.form}
                              style={{ paddingBottom: "0" }}
                            >
                              <label className={classes.label}>Title</label>
                              <TextField
                                id="outlined-adornment-amount"
                                name="title"
                                value={diary.title}
                                onChange={diaryChangeHandler}
                                error={error.titleError}
                                helperText={error.titleError}
                              />
                              <label className={classes.label}>
                                Description
                              </label>
                              <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                variant="outlined"
                                name="description"
                                onChange={diaryChangeHandler}
                                error={error.descriptionError}
                                value={diary.description}
                                helperText={error.descriptionError}
                              />
                              <span style={{ display: "flex" }}>
                                {data &&
                                  (data.role === "learner" ||
                                    data.role === "coach") && (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          color="default"
                                          sx={{ fontSize: 30 }}
                                          checked={diary.requestAcknowledgement}
                                          name="requestAcknowledgement"
                                          onChange={diaryChangeHandler}
                                        />
                                      }
                                      label="Request acknowledgement"
                                    />
                                  )}

                                {data &&
                                  data.role !== "learner" &&
                                  data.role !== "visitor" && (
                                    <RestrictFeature available={["premium"]}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            color="default"
                                            sx={{ fontSize: 30 }}
                                            checked={diary.submitToAllLearners}
                                            name="submitToAllLearners"
                                            onChange={diaryChangeHandler}
                                          />
                                        }
                                        label="Submit to all learners"
                                      />
                                    </RestrictFeature>
                                  )}
                              </span>

                              {/* File Upload Component */}
                              <ImageDropzone
                                diary={diary}
                                setDiary={setDiary}
                              />
                              {data && data.role === "learner" && (
                                <Fragment>
                                  <Accordion className={classes.accordianBox}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        Add learning activity
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.form}
                                      >
                                        <label className={classes.label}>
                                          Learning activity
                                        </label>
                                        <FormControl
                                          className={classes.field}
                                          error={error.chooseActivityError}
                                        >
                                          <InputLabel id="demo-simple-select-error-label">
                                            Choose Activity
                                          </InputLabel>
                                          <Select
                                            disabled={
                                              userData &&
                                              (userData.progress
                                                .apprenticeshipMessage !==
                                                "Practical Period In Progress" ||
                                                userData.status !== "On")
                                            }
                                            labelId="demo-simple-select-error-label"
                                            id="demo-simple-select-error"
                                            label="Choose Activity"
                                            value={learningActivity.type}
                                            onChange={
                                              learningActivityChangeHandler
                                            }
                                          >
                                            {learningActivityFilter.map(
                                              (listItem, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={listItem}
                                                  >
                                                    {listItem}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                        <div
                                          className={`addActivityAccordionDates
                               ${classes.divStyle}`}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={enLocale}
                                          >
                                            <DateTimePicker
                                             sx={{
                                              "& .MuiPickersToolbar-penIconButton": {
                                                display: "none",
                                              },
                                            }}
                                              maxDate={getDateFromUkString(
                                                userData?.endDate
                                              )}
                                              minDate={getDateFromUkString(
                                                userData?.startDate
                                              )}
                                              label="Start Date & Time"
                                              name="startDate"
                                              value={learningActivity.startDate}
                                              onChange={handleStartDateChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  onKeyDown={(e) =>
                                                    e.preventDefault()
                                                  }
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>

                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={enLocale}
                                          >
                                            <DateTimePicker
                                             sx={{
                                              "& .MuiPickersToolbar-penIconButton": {
                                                display: "none",
                                              },
                                            }}
                                              maxDate={getDateFromUkString(
                                                userData?.endDate
                                              )}
                                              minDate={getDateFromUkString(
                                                userData?.startDate
                                              )}
                                              label="End Date & Time"
                                              name="endDate"
                                              value={learningActivity.endDate}
                                              onChange={handleEndDateChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  onKeyDown={(e) =>
                                                    e.preventDefault()
                                                  }
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>
                                        </div>
                                        <div
                                          className={`addActivityAccordionTime ${classes.cardStyle}`}
                                        >
                                          {/* Time Inout comp */}
                                          <TimeInput
                                            setError={setError}
                                            error={error}
                                            learningActivity={learningActivity}
                                            setLearningActivity={
                                              setLearningActivity
                                            }
                                          />
                                        </div>
                                      </FormControl>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion className={classes.accordianBox}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>
                                        Add occupational standards
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.form}
                                      >
                                        <label className={classes.label}>
                                          Duties
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="duties"
                                          value={occupationalStandards.duties}
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Knowledge
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="knowledge"
                                          value={
                                            occupationalStandards.knowledge
                                          }
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Skills
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="skills"
                                          value={occupationalStandards.skills}
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Behaviour
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="behaviour"
                                          value={
                                            occupationalStandards.behaviour
                                          }
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                        <label className={classes.label}>
                                          Others
                                        </label>
                                        <TextField
                                          id="outlined-multiline-static"
                                          multiline
                                          rows={4}
                                          variant="outlined"
                                          name="others"
                                          value={occupationalStandards.others}
                                          onChange={
                                            occupationalStandardsChangeHandler
                                          }
                                        />
                                      </FormControl>
                                    </AccordionDetails>
                                  </Accordion>
                                </Fragment>
                              )}
                              <div
                                className={classes.buttons}
                                // style={{ marginTop: "10px" }}
                              >
                                <Button
                                  onClick={submitHandler}
                                  variant="contained"
                                  className={classes.button}
                                  sx={{ mr: 1 }}
                                >
                                  <Typography variant="h5">Submit</Typography>
                                </Button>
                                <Button
                                  onClick={resetHandler}
                                  variant="contained"
                                  className={classes.button}
                                >
                                  <Typography variant="h5">Reset</Typography>
                                </Button>
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                  ) : (
                    diaryRecords()
                  )}
                </Fragment>
              ) : (
                <div className={classes.skeleton} style={{ height: "845px" }}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton
                    variant="rect"
                    style={{ height: "calc(100% - 50px)" }}
                  />
                </div>
              )}
            </Fragment>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Container maxWidth="xl">
              {isDesktop && (
                <ProfileContainer role="learner" userData={userData} />
              )}
              {userData && data ? (
                <Box className={classes.cardStyle}>
                  <Card>
                    <CardContent className="card-content">
                      <Typography variant="h6" gutterBottom>
                        Diaries Progress
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: 3,
                          marginBottom: 3,
                        }}
                      >
                        <div className={classes.divStyle}>
                          <Typography gutterBottom>Diaries Added</Typography>
                          <label>{userData.progress.totalDiaries}</label>
                        </div>
                        <LinearProgress
                          value={userData.progress.totalDiaries}
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: 3,
                          marginBottom: 3,
                        }}
                      >
                        <div className={classes.divStyle}>
                          <Typography gutterBottom>OTJ Diaries</Typography>
                          <label>{userData.progress.totalOtjEntries}</label>
                        </div>
                        <LinearProgress
                          color="success"
                          value={userData.progress.totalOtjEntries}
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: 3,
                          marginBottom: 3,
                        }}
                      >
                        <div className={classes.divStyle}>
                          <Typography gutterBottom>Not OTJ Diaries</Typography>
                          <label>{userData.progress.totalNotOtjEntries}</label>
                        </div>
                        <LinearProgress
                          color="secondary"
                          value={userData.progress.totalNotOtjEntries}
                          variant="determinate"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                </div>
              )}{" "}
              {isDesktop && data && data._id === id && (
                <Fragment>
                  {userData ? (
                    <Box className={classes.cardStyle}>
                      <Card>
                        <CardContent className="card-content">
                          <Typography variant="h6" gutterBottom>
                            Help & Support
                          </Typography>
                          <Typography variant="body2">
                            Feel free to contact your coach to get training on
                            how to use the web app.
                            <br />
                            <br />
                            If you encounter any technical issues, you should
                            report them to your coach, training provider or
                            employer.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  ) : (
                    <div className={classes.skeleton}>
                      <Skeleton variant="text" height={45} />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" width="70%" />
                      <Skeleton variant="text" width="70%" height={45} />
                    </div>
                  )}
                </Fragment>
              )}
            </Container>
          </Grid>
        </Grid>

        {preview.show && (
          <AttachmentPreview
            type={preview.type}
            file={preview.file}
            closeModal={() => setPreview({ show: false })}
            onError={filePreviewError}
          />
        )}

        {data ? (
          <Fragment>{data.role !== "visitor" && diaryRecords()}</Fragment>
        ) : (
          <Fragment>
            {data?.role !== "visitor" && (
              <div className={classes.skeleton} style={{ height: "845px" }}>
                <Skeleton variant="text" height={50} />
                <Skeleton
                  variant="rect"
                  style={{ height: "calc(100% - 50px)" }}
                />
              </div>
            )}
          </Fragment>
        )}
      </Container>

      <ConfirmationModal
        open={confirmationModal}
        close={closeConfirmationModal}
        title="Confirmation"
        text="Are you sure you want to delete this entry?"
        ctaTitleText="Delete"
        cta={deleteEntry}
      />

      <ApprentiseshipStatusModal
        open={apprentiseshipStatusModal}
        close={() => setApprentiseshipStatusModal(false)}
      />

      {diaryModal ? (
        <UpdateDiaryModal
          userData={userData}
          openModal={openDiaryEditModal}
          closeModal={closeDiaryEditModal}
          data={singleEntry}
          refreshData={() => getData(filter)}
          setApprentiseshipStatusModal={setApprentiseshipStatusModal}
        />
      ) : null}
    </Fragment>
  );
};
