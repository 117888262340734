import React from "react";
import Iconify from "../../components/Iconify";
import { Typography, Stack } from "@mui/material";

const PriceListItem = ({ item }) => {
  const { text, isAvailable } = item;
  return (
    <Stack
      key={text}
      component="li"
      direction="row"
      alignItems="center"
      spacing={1.5}
      sx={{
        typography: "body2",
        color: isAvailable ? "text.primary" : "text.disabled",
      }}
    >
      <Iconify icon={"eva:checkmark-fill"} sx={{ width: 20, height: 20 }} />
      <Typography
        variant="h5"
        sx={{
          color: isAvailable ? "text.primary" : "text.disabled",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default PriceListItem;
