import React, { useEffect, useState, Fragment } from "react";
import "./LearnerInfoModal.css";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Modal,
  Button,
  Box,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "lightgray",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  icons: {
    cursor: "pointer",
  },
  indicator: {
    backgroundColor: "#5335ca",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

export default function LearnerInfoModal(props) {
  const classes = useStyles();
  const [learnerFaculty, setLearnerFaculty] = useState({
    coach: "",
    mentor: "",
    trainer: "",
    // manager: "",
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    let coachMentor = props.userData?.coachMentor;
    if (coachMentor?.length > 0) {
      let coach,
        mentor,
        trainer = "";
      coachMentor.map((faculty) => {
        if (faculty.role === "coach") {
          coach = faculty.name;
        } else if (faculty.role === "mentor") {
          mentor = faculty.name;
        } else if (faculty.role === "trainer") {
          trainer = faculty.name;
        }
        return "";
      });
      setLearnerFaculty({
        coach,
        mentor,
        trainer,
        // manager
      });
    }
  }, [props.userData]);

  return (
    // <Modal
    //   open={props.open}
    //   onClose={props.close}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.close}
      PaperProps={{
        sx: { width: matches ? "50%" : "100%" },
      }}
    >
      <Box sx={{ m: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawerHeader"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Learner Profile
          </Typography>
          <CloseIcon
            className={classes.icons}
            title="Close"
            onClick={props.close}
          />
        </Grid>

        {props.userData && (
          <Fragment>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Info" />
                <Tab label="Training Plan" />
              </Tabs>
            </Paper>

            {value === 0 ? (
              <div className="learnerProfileInfo">
                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    ULN (Unique Learner Number)
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.uln ? props.userData.uln : "-"}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Name
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.name}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Email
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.email}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Status
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.status} Programme
                  </Typography>
                </div>

                <hr />

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Apprenticeship Standard
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.apprenticeshipStandard}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Practical Period
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData?.monthlyHourMinutes?.length} weeks
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Start and End Date
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {/* {new Date(
                      Number(props.userData?.startDate)
                    ).toLocaleDateString("en-GB")} */}
                    {props.userData?.startDate} -{" "}
                    {/* {new Date(
                      Number(props.userData.endDate)
                    ).toLocaleDateString("en-GB")} */}
                    {props.userData.endDate}
                  </Typography>
                </div>

                <hr />

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Employer Name
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.employerName}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Training Provider
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {props.userData.trainingProviderName}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Coach
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {learnerFaculty.coach ? learnerFaculty.coach : "-"}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Mentor
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {learnerFaculty.mentor ? learnerFaculty.mentor : "-"}
                  </Typography>
                </div>

                <div className="learnerProfileInfo__group">
                  <Typography
                    component="h3"
                    className="learnerProfileInfo__subHeading"
                  >
                    Trainer
                  </Typography>
                  <Typography
                    component="p"
                    className="learnerProfileInfo__text"
                  >
                    {learnerFaculty.trainer ? learnerFaculty.trainer : "-"}
                  </Typography>
                </div>

              </div>
            ) : (
              <div className="editHoursModalSignUp__form">
                <div className="editHoursModalSignUp__table--wrapper">
                  <table className="editHoursModalSignUp__table">
                    <tbody>
                      <tr className="editHoursModalSignUp__table--row editHoursModalSignUp__table--row-heading">
                        <th>Weeks</th>
                        <th>Hours</th>
                        <th>Minutes</th>
                      </tr>

                      {props.userData.monthlyHourMinutes.map(
                        (duration, index) => (
                          <tr
                            className="editHoursModalSignUp__table--row"
                            key={index}
                          >
                            <td>
                              <>
                                <span>{duration?.weekName}</span>
                                <br />
                                <span>
                                  {duration?.startDate} - {duration?.endDate}
                                </span>
                              </>
                            </td>
                            <td>
                              {/* <input
                                type="number"
                                className="editHoursModalSignUp__table--input"
                                name="hours"
                                min="0"
                                value={duration?.targetHours}
                                disabled
                              /> */}
                              <span>{duration?.targetHours}</span>
                            </td>

                            <td>
                              {/* <input
                                type="number"
                                className="editHoursModalSignUp__table--input"
                                name="minutes"
                                min="0"
                                max="59"
                                value={duration?.targetMinutes}
                                disabled
                              /> */}
                              <span>{duration?.targetMinutes}</span>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Fragment>
        )}

        {/* <div className="modalFooter">
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button onClick={props.close} className={classes.button}>
              Close
            </Button>
          </div>
        </div> */}
      </Box>
    </Drawer>
  );
}
