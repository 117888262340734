import React, { useState, useContext } from "react";
import axios from "axios";
import { AppContext } from "../../context/appContext";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography,
  Button,
  Switch,
  InputLabel,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { capitalCase } from "capital-case";
import { getAllFaculties, setAllFaculties } from "../../redux/slices/faculty";
import { useDispatch } from "react-redux";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 1,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 2,
  },
  field: {
    marginBottom: 20,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
}));

const UpdateFaculty = ({
  open,
  close,
  tempFaculty,
  setTempFaculty,
  facultyRoles,
  setSuperAdminAlertModal,
  newFacultyValidations,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // data, setData, handleToggle, updateMessage, getUserData
  const { handleToggle, updateMessage, getUserData } = useContext(AppContext);
  const [facultyValidations, setFacultyValidations] = useState(
    newFacultyValidations
  );
  // const [age, setAge] = React.useState("");

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  // FormHandler For Edit/ Update Faculty Modal
  const tempFacultyChangeHandler = (e) => {
    let { name, value, checked } = e.target;

    if (name && name === "isFacultyActive") {
      setTempFaculty({
        ...tempFaculty,
        active: checked,
      });
    } else if (name && name === "role") {
      if (value === "super admin" && tempFaculty.role !== "super admin") {
        setSuperAdminAlertModal(true);
      }

      setTempFaculty({
        ...tempFaculty,
        role: value,
        attribute: value,
      });

      if (value && facultyValidations.role) {
        setFacultyValidations({
          ...facultyValidations,
          role: false,
        });
      }
    } else {
      setTempFaculty({
        ...tempFaculty,
        [name]: value,
      });

      if (value && facultyValidations[name]) {
        setFacultyValidations({
          ...facultyValidations,
          [name]: false,
        });
      }
    }
  };

  // To Update Faculty
  const updateFaculty = async () => {
    // 1- Validations
    if (tempFaculty.name === "") {
      setFacultyValidations({
        ...facultyValidations,
        name: true,
      });
      return;
    }
    if (tempFaculty.role === "") {
      setFacultyValidations({
        ...facultyValidations,
        role: true,
      });
      return;
    }

    let faculty = {
      ...tempFaculty,
      active: tempFaculty.active,
      adminFunction: true,
    };

    // 2- Api
    try {
      handleToggle(true);
      await axios
        .put("faculty", faculty, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          updateMessage(response.data.message);
          if (tempFaculty.role == "super admin") {
            window.location.reload();
            return;
          }
          getUserData();
          // dispatch(setAllFaculties(response.data.data));
          dispatch(getAllFaculties());
          close();
          handleToggle(false);
          setFacultyValidations(newFacultyValidations);
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <Box sx={modalStyle} className="modalContainer">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="modalHeader"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Faculty
          </Typography>
          <CloseIcon className={classes.icons} title="Close" onClick={close} />
        </Grid>
        <FormGroup sx={{ paddingTop: 3 }}>
          <Grid container spacing={3} className={classes.field}>
            <Grid item sm={12} md={12} className="fullWidthInput">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={tempFaculty.name}
                onChange={(e) => tempFacultyChangeHandler(e)}
                error={facultyValidations.name}
                helperText={
                  facultyValidations.name && "This can't be empty."
                }
              />
            </Grid>
          </Grid>
          <TextField
            label="Work Email"
            variant="outlined"
            className={classes.field}
            name="email"
            value={tempFaculty.email}
            onChange={(e) => tempFacultyChangeHandler(e)}
            disabled
          />
          {/* <Autocomplete
                        limitTags={1}
                        className={`${classes.field} materialRolesAutocomplete`}
                        options={facultyRoles}
                        getOptionLabel={(option) => option.title}
                        defaultValue={
                            facultyRoles
                                ? facultyRoles.find(
                                    (facultyRole) =>
                                        facultyRole.title === tempFaculty.role
                                )
                                : ""
                        }
                        onSelect={(e) =>
                            tempFacultyChangeHandler(e, "role")
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled",
                                }}
                                label="Faculty Role"
                                variant="outlined"
                                error={facultyValidations.role}
                                helperText={
                                    facultyValidations.role &&
                                    "This can't be empty."
                                }
                                className="capitalizeAutoComplete"
                            />
                        )}
                    /> */}
          <FormControl>
            <InputLabel>Faculty Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tempFaculty.attribute.toLowerCase()}
              onChange={tempFacultyChangeHandler}
              label="Faculty Role"
              name="role"
            >
              {facultyRoles.map((listItem, index) => {
                return (
                  <MenuItem key={index} value={listItem.title}>
                    {capitalCase(listItem.title, "PascalCase")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div className="modalFooter editFacultyModal__footer">
            {/* <FormControlLabel
              className="materialSwitchLabel"
              control={
                <Switch
                  checked={
                    tempFaculty &&
                    (tempFaculty.active || tempFaculty.role === "super admin")
                  }
                  onChange={(e) => tempFacultyChangeHandler(e)}
                  name="isFacultyActive"
                  className="materialSwitchBtn"
                  disabled={tempFaculty.role === "super admin" ? true : false}
                />
              }
              label={tempFaculty.active ? "Active" : "Inactive"}
            /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={updateFaculty}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                className={classes.buttonn}
                onClick={close}
              >
                Cancel
              </Button>
            </div>
          </div>
        </FormGroup>
      </Box>
    </Modal>
  );
};

export default UpdateFaculty;
