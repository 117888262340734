import { createSlice } from "@reduxjs/toolkit";
// utils
// import { getState } from "../store";
import axios from "axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  attribute: "All",
  faculties: [],
  allFaculties: [],
  query: "",
};

const slice = createSlice({
  name: "faculty",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setAllFaculties(state, action) {
      state.isLoading = false;
      state.faculties = action.payload;
    },

    setAllFacultiesForForm(state, action) {
      state.isLoading = false;
      state.allFaculties = action.payload;
    },

    setQuery(state, action) {
      state.query = action.payload;
    },

    setAttribute(state, action) {
      state.attribute = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setQuery, setAttribute, setAllFaculties } = slice.actions;

// ----------------------------------------------------------------------
function getAllFacultiesForm() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/faculty/faculties`,
        {
          attribute: "All",
          query: "",
        },
        {
          config: { handlerEnabled: true },
        }
      );
      dispatch(slice.actions.setAllFacultiesForForm(response.data.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllFaculties() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const state = getState();
      const { query, attribute } = state.faculty;
      // console.log(query, attribute);
      const response = await axios.post(
        `/faculty/faculties`,
        {
          attribute,
          query,
        },
        {
          config: { handlerEnabled: true },
        }
      );
      dispatch(slice.actions.setAllFaculties(response.data.data));
      dispatch(getAllFacultiesForm());
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCoaches() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/faculty/coaches`, {
        config: { handlerEnabled: true },
      });
      dispatch(slice.actions.setAllFaculties(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
