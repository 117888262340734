import * as React from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";

export default function MoreActions({ faculty, edit, deleteFaculty }) {
  const pageRole = window.location.pathname.split("/")[1];
  const { data, handleToggle, updateMessage } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const visitFacultyPage = (id, role) => {
    window.open(`${window.origin}/${role}/${id}`);
  };

  const restrictAction = () => {
    const { attribute: listUserAttribute, _id: listUserId } = faculty;
    const { _id: userId, role: userRole, attribute } = data;
    if (userId === listUserId) {
      return {
        isRestrict: true,
        message: "You can edit your info from navbar profile icon.",
      };
    } // restrict user for himself
    if (listUserAttribute === attribute) {
      return {
        isRestrict: true,
        message: "You don't have permission for this user.",
      };
    } // restrict user for the same role

    if (attribute === "super admin") {
      return {
        isRestrict: false,
        message: "",
      };
    } // don't restrict admin for any user
    if (attribute === "admin") {
      if (listUserAttribute === "super admin") {
        return {
          isRestrict: true,
          message: "You don't have permission for this user.",
        };
      } else {
        return {
          isRestrict: false,
          message: "",
        };
      }
    }
    if (attribute === "manager" && listUserAttribute === "coach") {
      return {
        isRestrict: false,
        message: "",
      };
    }
    return {
      isRestrict: true,
      message: "You don't have permission for this user.",
    };
  };

  let { isRestrict, message } = restrictAction();

  return (
    <div>
      {isRestrict ? (
        <Tooltip disabled={true} placement="top" title={message}>
          <span>
            {/* <MoreVertIcon sx={{ cursor: "not-allowed" }} /> */}
            <IconButton>
              <MoreVertIcon sx={{ cursor: "not-allowed", opacity: 0.5 }} />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          key={1 * Math.random()}
          onClick={() => {
            handleClose();
            visitFacultyPage(faculty._id, faculty.role);
          }}
        >
          <PersonOutlineSharpIcon fontSize="small" />
          &nbsp;&nbsp;Profile
        </MenuItem>
        {pageRole === "admin" && (
          <MenuItem
            key={3 * Math.random()}
            onClick={() => {
              handleClose();
              edit(faculty);
            }}
          >
            <EditIcon fontSize="small" />
            &nbsp;&nbsp;Edit
          </MenuItem>
        )}

        {pageRole === "admin" && (
          <MenuItem
            key={4 * Math.random()}
            onClick={() => {
              handleClose();
              deleteFaculty(faculty);
            }}
          >
            <DeleteOutlineIcon fontSize="small" />
            &nbsp;&nbsp;Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
