// @mui
// import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Switch,
  Container,
  Typography,
  Stack,
  Card,
  Button,
  Chip,
} from "@mui/material";
import { styled } from "@mui/styles";
// _mock_
// import { _pricingPlans } from "../_mock";
// sections
// import { PricingPlanCard } from "../sections/pricing";

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import axios from "axios";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  minHeight: "100%",
  paddingTop: "15px",
  paddingBottom: "10px",
}));

// ----------------------------------------------------------------------


export default function InvalidSubscription() {
  const [pricing, setPricing] = useState([]);

  return (
    <RootStyle>
      <Container maxWidth="xl">
        <Typography variant="h3" align="center" paragraph>
          Flexible plans for your
          <br /> apprenticeship programme
        </Typography>
        <Typography align="center" sx={{ color: "text.secondary" }}>
          Choose your plan and make modern online conversation magic
        </Typography>
      </Container>
    </RootStyle>
  );
}
