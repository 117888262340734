import { Box, Typography } from "@mui/material";
import React from "react";
import PageNotFoundIllustration from "../../components/PageNotFoundIllustration";

const PricingPlaneNotFound = () => {
  return (
    <Box sx={{ px: 2, maxWidth: 480, margin: "auto", textAlign: "center" }}>
      <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      <Typography
        fontWeight={"bold"}
        variant="h4"
        align="center"
        paragraph
        gutterBottom
      >
        No plans found.
      </Typography>
      <Typography
        lineHeight={2}
        variant="h5"
        align="center"
        sx={{ color: "text.secondary" }}
      >
        Sorry, we couldn’t find the right price for your plan, But don't worry,
        you can always contact to your administrator to get the right price.
      </Typography>
    </Box>
  );
};

export default PricingPlaneNotFound;
