import moment from "moment";
import { daysBetween } from "./functions";

// Function with leftovers minutes merge into last week
// const calculateLearnerHoursV2 = (newLearner) => {
//   let totalDays = daysBetween(newLearner.startDate, newLearner.endDate);
//   let totalTargetMinutes = newLearner.durationInHours * 60;

//   let totalTargetMinutesPerDay = totalTargetMinutes / totalDays;

//   let tempStartDate = newLearner.startDate;
//   let daysLeft = totalDays;
//   let leftOverMinutes = 0;
//   let targetDurations = [];

//   if (totalDays > 0) {
//     for (let i = 0; i < totalDays && daysLeft > 7; i++) {
//       let tempEndDate = moment(tempStartDate).add(6, "days")._d;
//       let weekName = moment.localeData().ordinal(i + 1) + " Week";

//       leftOverMinutes +=
//         ((totalTargetMinutesPerDay * 7) % 60) -
//         Math.floor((totalTargetMinutesPerDay * 7) % 60);

//       targetDurations.push({
//         startDate: tempStartDate.toLocaleDateString("en-GB", {
//           timeZone: "Europe/London",
//         }),
//         endDate: tempEndDate.toLocaleDateString("en-GB", {
//           timeZone: "Europe/London",
//         }),
//         targetHours: Math.floor((totalTargetMinutesPerDay * 7) / 60),
//         targetMinutes: Math.floor((totalTargetMinutesPerDay * 7) % 60),
//         isHoursChanged: false,
//         isMonthsChanged: false,
//         weekName,
//         daysCount: 7,
//       });

//       daysLeft -= 7;
//       tempStartDate = moment(tempEndDate).add(1, "days")._d;
//     }

//     if (daysLeft < totalDays) {
//       let tempEndDate = moment(tempStartDate).add(daysLeft, "days")._d;
//       let weekName =
//         moment.localeData().ordinal(targetDurations.length + 1) + " Week";

//       leftOverMinutes +=
//         ((totalTargetMinutesPerDay * daysLeft) % 60) -
//         Math.floor((totalTargetMinutesPerDay * daysLeft) % 60);

//       let lastWeekMinutes = Math.floor(
//         totalTargetMinutesPerDay * daysLeft + leftOverMinutes
//       );

//       targetDurations.push({
//         startDate: tempStartDate.toLocaleDateString("en-GB", {
//           timeZone: "Europe/London",
//         }),
//         endDate: tempEndDate.toLocaleDateString("en-GB", {
//           timeZone: "Europe/London",
//         }),

//         targetHours: Math.floor(lastWeekMinutes / 60),
//         targetMinutes: Math.floor(lastWeekMinutes % 60),

//         isHoursChanged: false,
//         isMonthsChanged: false,
//         weekName,
//         daysCount: daysLeft,
//       });
//     }

//     let learner = {
//       ...newLearner,
//       monthlyHourMinutes: targetDurations,
//       monthlyHourMinutesUpdated: targetDurations,
//       totalMinutes: totalTargetMinutes,
//     };

//     return learner;
//   } else {
//     return newLearner;
//   }
// };

// Function with leftovers minutes merge into relative week
const calculateLearnerHours = (newLearner) => {
  let totalDays = daysBetween(newLearner.startDate, newLearner.endDate);
  let totalTargetMinutes = newLearner.durationInHours * 60;

  let totalTargetMinutesPerDay = totalTargetMinutes / totalDays;

  let tempStartDate = newLearner.startDate;
  let daysLeft = totalDays;
  let leftOverMinutes = 0;
  let targetDurations = [];

  if (totalDays > 0) {
    for (let i = 0; i < totalDays && daysLeft > 7; i++) {
      let tempEndDate = moment(tempStartDate).add(6, "days")._d;
      let weekName = moment.localeData().ordinal(i + 1) + " Week";

      leftOverMinutes +=
        ((totalTargetMinutesPerDay * 7) % 60) -
        Math.floor((totalTargetMinutesPerDay * 7) % 60);

      // console.log("leftOverMinutes before", leftOverMinutes);

      let lastWeekMinutes;
      if (leftOverMinutes > 1) {
        lastWeekMinutes = totalTargetMinutesPerDay * 7 + 1;
        leftOverMinutes = leftOverMinutes - 1;
      } else {
        lastWeekMinutes = totalTargetMinutesPerDay * 7;
      }
      // console.log("leftOverMinutes after", leftOverMinutes);
      targetDurations.push({
        startDate: tempStartDate.toLocaleDateString("en-GB", {
          timeZone: "Europe/London",
        }),
        endDate: tempEndDate.toLocaleDateString("en-GB", {
          timeZone: "Europe/London",
        }),
        targetHours: Math.floor(lastWeekMinutes / 60),
        targetMinutes: Math.floor(lastWeekMinutes % 60),
        isHoursChanged: false,
        isMonthsChanged: false,
        weekName,
        daysCount: 7,
      });

      daysLeft -= 7;
      tempStartDate = moment(tempEndDate).add(1, "days")._d;
    }

    if (daysLeft < totalDays) {
      let tempEndDate = moment(tempStartDate).add(daysLeft, "days")._d;
      let weekName =
        moment.localeData().ordinal(targetDurations.length + 1) + " Week";

      leftOverMinutes +=
        ((totalTargetMinutesPerDay * daysLeft) % 60) -
        Math.floor((totalTargetMinutesPerDay * daysLeft) % 60);

      let lastWeekMinutes = Math.floor(
        totalTargetMinutesPerDay * daysLeft + leftOverMinutes
      );

      targetDurations.push({
        startDate: tempStartDate.toLocaleDateString("en-GB", {
          timeZone: "Europe/London",
        }),
        endDate: tempEndDate.toLocaleDateString("en-GB", {
          timeZone: "Europe/London",
        }),

        targetHours: Math.floor(lastWeekMinutes / 60),
        targetMinutes: Math.floor(lastWeekMinutes % 60),

        isHoursChanged: false,
        isMonthsChanged: false,
        weekName,
        daysCount: daysLeft,
      });
    }

    let learner = {
      ...newLearner,
      monthlyHourMinutes: targetDurations,
      monthlyHourMinutesUpdated: targetDurations,
      totalMinutes: totalTargetMinutes,
    };

    return learner;
  } else {
    return newLearner;
  }
};

export default calculateLearnerHours;
