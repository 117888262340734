import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "../../redux/store";
import {
  getLearners,
  setProgrameStatus,
  setQuery,
  setTargetStatus,
} from "../../redux/slices/learner";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { InputAdornment } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  flex: 1,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  border: "1px solid #ccc",
  borderRadius: "5px",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      //   width: "12ch",
      width: "100%",
      "&:focus": {
        width: "100%",
      },
      "&:hover": {},
    },
  },
}));

const Toolbar = () => {
  const dispatch = useDispatch();
  const { programeStatus, targetStatus, query } = useSelector(
    (state) => state.learner
  );
  let { id } = useParams();

  const handleChange = (event) => {
    dispatch(setTargetStatus(event.target.value));
    dispatch(getLearners(id));
  };

  const handleChangePrograme = (event) => {
    dispatch(setProgrameStatus(event.target.value));
    dispatch(getLearners(id));
  };

  const handleSearch = React.useCallback(
    debounce(async (event) => {
      // dispatch(setQuery(event.target.value));
      dispatch(getLearners(id));
    }, 1000),
    []
  );
  const clearSearch = (event) => {
    dispatch(setQuery(""));
    dispatch(getLearners(id));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginY: 2,
        marginX: 1,
        gap: 2,
      }}
    >
      {/* <Search fullWidth>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          type="text"
          onKeyDown={handleSearch}
        />
      </Search> */}
      <TextField
      placeholder="Search By Name"
        fullWidth
        label="Search"
        variant="outlined"
        sx={{
          "& .MuiInputBase-input": {
            padding: "8.5px 0px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {query !== "" && (
                <CancelIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    clearSearch();
                  }}
                />
              )}
            </InputAdornment>
          ),
        }}
        name="query"
        // onKeyDown={(e) => handleKeyDown(e)}
        value={query}
        onChange={(e) => {
          dispatch(setQuery(e.target.value));
          handleSearch(e);
        }}
      />
      <FormControl size="small" sx={{ minWidth: { xs: 80, sm: 140 } }}>
        <InputLabel id="demo-simple-select-labels">Programme</InputLabel>
        <Select
          labelId="demo-simple-select-labels"
          id="demo-simple-selects"
          value={programeStatus}
          label="Programme"
          onChange={handleChangePrograme}
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"On"}>On</MenuItem>
          <MenuItem value={"Break"}>On Break</MenuItem>
          <MenuItem value={"Off"}>Off</MenuItem>
          <MenuItem value={"Completed"}>Completed</MenuItem>
        </Select>
      </FormControl>

      <FormControl size="small" sx={{ minWidth: { xs: 80, sm: 140 } }}>
        <InputLabel id="demo-simple-select-label">Target</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={targetStatus}
          label="Target"
          onChange={handleChange}
        >
          <MenuItem value={"All"}>All</MenuItem>
          <MenuItem value={"On"}>On</MenuItem>
          <MenuItem value={"Below"}>Below</MenuItem>
          {/* <MenuItem value={"Over"}>Over</MenuItem> */}
        </Select>
      </FormControl>

      
    </Box>
  );
};

export default Toolbar;
