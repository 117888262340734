import { combineReducers } from "redux";
// slices
import learnerReducer from "./slices/learner";
import facultyReducer from "./slices/faculty";
// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  learner: learnerReducer,
  faculty: facultyReducer,
});

export default rootReducer;
