import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import "./Trainer.css";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Card,
  Chip,
  CardContent,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ProfileContainer from "../../components/ProfileContainer/ProfileContainer";
import HelpAndSupport from "../../components/HelpAndSupport/HelpAndSupport";
import RefreshButton from "../../components/RefreshButton/Refresh";
import { AppContext } from "../../context/appContext";
import LearnerListNew from "../../components/LearnerListNew/LearnerListNew";
import { Charts } from "../../components/Charts/Charts";
import { useDispatch } from "../../redux/store";
import { getLearners } from "../../redux/slices/learner";
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStylee: {
    display: "flex",
    padding: 10,
    gap: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },
  divStyle3: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  divStyle4: {
    display: "flex",
  },
  labelstyle: {
    paddingLeft: "10",
  },

  boxStylePie: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "@media (max-width: 900px)": {
      marginTop: 40,
    },
  },
  field: {
    marginBottom: 20,
  },
  formStylee: {
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    justifyContent: "space-between",
  },

  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  boxStyleLine: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    width: "100%",
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 10,
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
  },
  profileCardText: {
    fontSize: 16,
    color: "white",
  },
  profileCardText1: {
    color: "white",
    fontSize: 12,
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  text2: {
    marginTop: "30px",
  },
  text1Sub: {
    fontSize: "1.1rem",
  },
  typoText1: {
    fontFamily: "Poppins",
    fontSize: 9,
    fontWeight: 600,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  barOne: {
    color: "#B4A6EB",
  },
  barTwo: {
    color: "#7CBD1A",
  },
  barThree: {
    color: "#BD1A41",
  },
  barFour: {
    color: "#0000FF",
  },
  barFive: {
    color: "#FACE6B",
  }, barSix: {
    color: "#BABBBC",
  },barSeven: {
    color: "#3ACFF8",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    marginTop: 20,
  },
}));

export const TrainerPage = () => {
  const dispatch = useDispatch();

  // data, setData, handleToggle, updateMessage, getUserData
  const { data, updateMessage } = useContext(AppContext);

  const classes = useStyles();
  const [trainerData, setTrainerData] = useState(null);

  let helpAndSupportMessage = `Feel free to contact us and get support from the ${
    trainerData && trainerData.trainer.rawCompanyName
  } admin team.`;

  let { id } = useParams();
  let url = "faculty/trainer/".concat(id);
  const isDesktop = window.innerWidth > 899 ? true : false;

  const getUserData = async () => {
    try {
      await axios
        .get(url, {
          config: { handlerEnabled: true },
        })
        .then((response) => {
          setTrainerData(response.data.data);
        });
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  const getAllLearners = async () => {
    dispatch(getLearners(id));
  };

  useEffect(() => {
    getUserData();
    getAllLearners();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // To Fetch All Data When User Back TYo Page After Visiting Another Tab
  const onVisibilityChange = async () => {
    let hidden;
    // let visibilityChange;

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      // visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      // visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      // visibilityChange = "webkitvisibilitychange";
    }

    if (!document[hidden]) {
      getUserData();
      getAllLearners();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      component="main"
      maxWidth="xl"
      className="pd-bottom-40"
      sx={{ paddingLeft: "0", paddingRight: "0" }}
    >
      <Container maxWidth="xl">
        <Box>
          {!isDesktop && (
            <ProfileContainer userData={trainerData && trainerData.trainer} />
          )}
        </Box>

        <Fragment>
          {data ? (
            <Box className={classes.boxStyle}>
              <div className="totalPerformance">
                <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
                  Total Performance
                </Typography>
                <RefreshButton />
              </div>

              <Grid container className="totalPerformanceBoxTrainerPage">
              <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners signed up
                    </Typography>
                    <Typography component="p" variant="h4">
                      {trainerData && trainerData.overAllPerformance.learnersSignedUp}
                    </Typography>
                  </Fragment>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners on programme
                    </Typography>
                    <Typography component="p" variant="h4">
                      {trainerData &&
                        trainerData.overAllPerformance.onProgramme}
                    </Typography>
                  </Fragment>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners on target
                    </Typography>
                    <Typography component="p" variant="h4">
                      {trainerData && trainerData.overAllPerformance.onTarget}
                    </Typography>
                  </Fragment>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners below target
                    </Typography>
                    <Typography component="p" variant="h4">
                      {trainerData &&
                        trainerData.overAllPerformance.belowTarget}
                    </Typography>
                  </Fragment>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                  <Fragment>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ flexGrow: 1 }}
                    >
                      Learners over target
                    </Typography>
                    <Typography component="p" variant="h4">
                      {trainerData && trainerData.overAllPerformance.overTarget}
                    </Typography>
                  </Fragment>
                </Grid> */}
              </Grid>
            </Box>
          ) : (
            <div className={classes.skeleton}>
              <Skeleton variant="text" height={45} />
              <div
                style={{ display: "flex", flexWrap: "wrap", margin: "0 -10px" }}
              >
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
                <div style={{ padding: "10px", flex: "1" }}>
                  <Skeleton variant="rect" height={150} />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      </Container>

      <Grid container className="mainSection">
        <Grid item xs={12} md={8.5}>
          <Container
            maxWidth="xl"
            // style={{ height: data ? "calc(100% - 20px)" : "" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              className={`learnerListWrapper ${classes.boxStyle}`}
            >
              <Fragment>
                {data ? (
                  <Typography
                    className={`listHeader ${classes.divStyle}`}
                    variant="h6"
                    component="h3"
                    sx={{ flexGrow: 1 }}
                  >
                    All Learners
                  </Typography>
                ) : (
                  <Skeleton variant="text" height={45} />
                )}
              </Fragment>

              <LearnerListNew maxHeight={550}></LearnerListNew>
            </Grid>
            {!isDesktop && data && data._id === id && (
              <HelpAndSupport message={helpAndSupportMessage} />)}
          </Container>
        </Grid>

        <Grid item xs={12} md={3.5}>
          <Container maxWidth="xl">
            {isDesktop && (
              <ProfileContainer userData={trainerData && trainerData.trainer} />
            )}

              {isDesktop && data && data._id === id && (
              <HelpAndSupport message={helpAndSupportMessage} />)}

            <Fragment>
              {data ? (
                <Box className={classes.cardStyle}>
                  <Card>
                    <CardContent className="card-content">
                      <Typography variant="h6" gutterBottom>
                        Learners Progress
                      </Typography>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>On Target</Typography>
                          <label>
                            {trainerData &&
                              trainerData.overAllProgress.onTarget}
                            %
                          </label>
                        </div>
                        <LinearProgress
                          color="inherit"
                          className={classes.barTwo}
                          value={
                            trainerData && trainerData.overAllProgress.onTarget
                          }
                          variant="determinate"
                        />
                      </Box>
                      <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>Below Target</Typography>
                          <label>
                            {trainerData &&
                              trainerData.overAllProgress.belowTarget}
                            %
                          </label>
                        </div>
                        <LinearProgress
                          color="inherit"
                          className={classes.barThree}
                          value={
                            trainerData &&
                            trainerData.overAllProgress.belowTarget
                          }
                          variant="determinate"
                        />
                      </Box>
                      {/* <Box
                        sx={{ width: "100%", marginTop: 3, marginBottom: 3 }}
                      >
                        <div className={classes.divStyle2}>
                          <Typography gutterBottom>Over Target</Typography>
                          <label>
                            {trainerData &&
                              trainerData.overAllProgress.overTarget}
                            %
                          </label>
                        </div>
                        <LinearProgress
                          value={
                            trainerData &&
                            trainerData.overAllProgress.overTarget
                          }
                          variant="determinate"
                          color="inherit"
                          className={classes.barFour}
                        />
                      </Box> */}
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                <div className={classes.skeleton}>
                  <Skeleton variant="text" height={50} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                  <Skeleton variant="text" height={35} />
                  <Skeleton variant="text" height={15} />
                </div>
              )}
            </Fragment>

            <Box className={classes.cardStyle}>
              <Card>
                <CardContent className="card-content">
                  <Typography variant="h6" gutterBottom>
                    Learners Data
                  </Typography>

                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 2.5,
                      marginBottom: 2.5,
                    }}
                  >
                    <div className={classes.divStyle2}>
                      <Typography gutterBottom>On Programme
                      <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#FACE6B",
                            border: `1px solid #FACE6B`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            trainerData &&
                            trainerData.overAllProgress.onProgrammeCount &&
                            trainerData.overAllProgress.onProgrammeCount
                          }
                        />
                      </Typography>
                      <label>
                        {trainerData &&
                          trainerData.overAllProgress.onProgramme &&
                          trainerData.overAllProgress.onProgramme}
                        %
                      </label>
                    </div>
                    <LinearProgress
                      color="inherit"
                      className={classes.barFive}
                      value={
                        trainerData &&
                        trainerData.overAllProgress.onProgramme &&
                        trainerData.overAllProgress.onProgramme
                      }
                      variant="determinate"
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 2.5,
                      marginBottom: 2.5,
                    }}
                  >
                    <div className={classes.divStyle2}>
                      <Typography gutterBottom>On break
                      <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#3ACFF8",
                            border: `1px solid #3ACFF8`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            trainerData &&
                            trainerData.overAllProgress.onBreakCount &&
                            trainerData.overAllProgress.onBreakCount
                          }
                        />
                      </Typography>
                      <label>
                        {trainerData &&
                          trainerData.overAllProgress.onBreak &&
                          trainerData.overAllProgress.onBreak}
                        %
                      </label>
                    </div>
                    <LinearProgress
                      color="inherit"
                      className={classes.barSeven}
                      value={
                        trainerData &&
                        trainerData.overAllProgress.onBreak &&
                        trainerData.overAllProgress.onBreak
                      }
                      variant="determinate"
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 2.5,
                      marginBottom: 2.5,
                    }}
                  >
                    <div className={classes.divStyle2}>
                      <Typography gutterBottom>Off Programme
                      <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#BABBBC",
                            border: `1px solid #BABBBC`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            trainerData &&
                            trainerData.overAllProgress.offProgrammeCount &&
                            trainerData.overAllProgress.offProgrammeCount
                          }
                        />
                      </Typography>
                      <label>
                        {trainerData &&
                          trainerData.overAllProgress.offProgramme &&
                          trainerData.overAllProgress.offProgramme}
                        %
                      </label>
                    </div>
                    <LinearProgress
                      color="inherit"
                      className={classes.barSix}
                      value={
                        trainerData &&
                        trainerData.overAllProgress.offProgramme &&
                        trainerData.overAllProgress.offProgramme
                      }
                      variant="determinate"
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 2.5,
                      marginBottom: 2.5,
                    }}
                  >
                    <div className={classes.divStyle2}>
                      <Typography gutterBottom>Completed
                      <Chip
                        size="small"
                          sx={{
                            marginLeft: "5px",
                            color: "#7CBD1A",
                            border: `1px solid #7CBD1A`,
                            backgroundColor: `transparent !important`,
                          }}
                          label={
                            trainerData &&
                            trainerData.overAllProgress.completedCount &&
                            trainerData.overAllProgress.completedCount
                          }
                        />
                      </Typography>
                      <label>
                        {trainerData &&
                          trainerData.overAllProgress.completed &&
                          trainerData.overAllProgress.completed}
                        %
                      </label>
                    </div>
                    <LinearProgress
                      color="inherit"
                      className={classes.barTwo}
                      value={
                        trainerData &&
                        trainerData.overAllProgress.completed &&
                        trainerData.overAllProgress.completed
                      }
                      variant="determinate"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Container>
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Box>
          <Grid container>
            <Grid item xs={12}>
              {/* {trainerData && <Charts data={trainerData.graphData} />} */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Container>
  );
};
