import { createSlice } from "@reduxjs/toolkit";
// utils
// import { getState } from "../store";
import axios from "axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  programeStatus: "All",
  targetStatus: "All",
  learners: [],
  query: "",
};

const slice = createSlice({
  name: "learner",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setAllLearners(state, action) {
      state.isLoading = false;
      state.learners = action.payload;
    },

    setQuery(state, action) {
      state.query = action.payload;
    },

    setTargetStatus(state, action) {
      state.targetStatus = action.payload;
    },

    setProgrameStatus(state, action) {
      state.programeStatus = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setQuery, setTargetStatus, setProgrameStatus, setAllLearners } =
  slice.actions;

// ----------------------------------------------------------------------
export function getLearners(id) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const state = getState();
      const { query, targetStatus ,programeStatus} = state.learner;
      // console.log(query, targetStatus);
      const response = await axios.post(
        `/faculty/learners/${id}`,
        {
          programeStatus,
          targetStatus,
          query,
        },
        {
          config: { handlerEnabled: true },
        }
      );
      dispatch(slice.actions.setAllLearners(response.data.data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}
