import React, { useState, useEffect, useContext, Fragment } from "react";
import "./SignIn.css";
import CompanyListModal from "../../components/CompanyListModal/CompanyListModal";
import axios from "axios";
import jwt from "jwt-decode";
import CssBaseline from "@mui/material/CssBaseline";
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography, Checkbox, FormControlLabel, TextField, Avatar, Button } from "@mui/material";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { validateEmail } from "../../utils/functions";

const useStyles = makeStyles(() => ({
  mainHeight: {
    height: "calc(100vh - 64px)",
    display: "flex",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#5335CA",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  button: {
    background: "#5335CA",
    color: "white",
    transition: "background .3s linear",
    "&:hover": {
      background: "#4b27dd",
    },
  },
}));

export const SignIn = () => {
  // data, setData, handleToggle, updateMessage, getUserData
  const { setData, handleToggle, updateMessage } = useContext(AppContext);

  const classes = useStyles();
  const history = useHistory();

  // States
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companies, setCompanies] = useState("");
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
    rememberMe: false,
    emailError: "",
    passwordError: "",
  });

  useEffect(() => {
    let token = localStorage.getItem("7-star-training");
    if (token) {
      let decodedToken = jwt(token.replace("Bearer ", ""));
      history.push(`/${decodedToken.role}/${decodedToken.id}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // FormHandler
  const loginChangeHandler = (e) => {
    let { name, value, checked } = e.target;
    if (value && loginInfo[name + "Error"]) {
      setLoginInfo({
        ...loginInfo,
        [name]: value,
        [name + "Error"]: "",
      });
    } else {
      if (name === "rememberMe") {
        setLoginInfo({
          ...loginInfo,
          [name]: checked,
        });
      } else {
        setLoginInfo({
          ...loginInfo,
          [name]: value,
        });
      }
    }
  };

  const isValid = () => {
    let emailError = "Please enter valid email";
    let passwordError = "Please enter password";
    if (loginInfo.email && validateEmail(loginInfo.email)) {
      emailError = "";
    }
    if (loginInfo.password) {
      passwordError = "";
    }
    if (emailError || passwordError) {
      setLoginInfo({
        ...loginInfo,
        emailError,
        passwordError,
      });
      return false;
    }
    return true;
  };

  // OnSubmit
  const onSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      try {
        handleToggle(true);
        await axios
          .post(`general/companies`, loginInfo)
          .then(async (response) => {
            handleToggle(false);
            sessionStorage.setItem("7-star-training-temp", response.data.token);
            if (response.data.data.length > 1) {
              setCompanies(response.data.data);
              setShowCompanyModal(!showCompanyModal);
            } else {
              try {
                await axios
                  .get(
                    `general/login/${response.data.data[0].sanitizeCompanyName}`,
                    {
                      config: { handlerEnabled: true },
                    }
                  )
                  .then((response) => {
                    localStorage.setItem(
                      "7-star-training",
                      response.data.token
                    );
                    setData(response.data.data);

                    // let loginRedirect = sessionStorage.getItem("loginRedirect");

                    // if (loginRedirect) {
                    //   let redirectRole = loginRedirect.split("/")[3];
                    //   let redirectId = loginRedirect.split("/")[4];
                    //   sessionStorage.removeItem("loginRedirect")
                    //   history.push(`/${redirectRole}/${redirectId}`);

                    // } else {
                    //   history.push(`/${response.data.data.role}/${response.data.data._id}`);
                    // }

                    history.push(`/${response.data.data.role}/${response.data.data._id}`);
                  });
              } catch (e) {
                handleToggle(false);
                if (e.response && e.response.data) {
                  updateMessage(e.response.data.message);
                }
              }
            }
          });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  return (
    <Fragment>
      <div className="cosmetics">
        <Container component="main" maxWidth="xs" className={classes.mainHeight}>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={loginInfo.email || ''}
                onChange={(e) => loginChangeHandler(e)}
                error={loginInfo.emailError === "" ? false : true}
                helperText={loginInfo.emailError}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={loginInfo.password || ''}
                onChange={(e) => loginChangeHandler(e)}
                error={loginInfo.passwordError === "" ? false : true}
                helperText={loginInfo.passwordError}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={loginInfo.rememberMe}
                    color="primary"
                    name="rememberMe"
                    onChange={loginChangeHandler}
                  />
                }
                label="Remember me for 30 days"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.button}
                onClick={(e) => onSubmit(e)}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    style={{ textDecoration: "none", marginTop: ".5rem" }}
                  >
                    <RouterLink
                      to="/forget-password"
                      style={{ textDecoration: "none" }}
                    >
                      Forget password?
                    </RouterLink>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>

      <CompanyListModal
        open={showCompanyModal}
        close={() => setShowCompanyModal(false)}
        companies={companies}
      />
    </Fragment>
  );
};
