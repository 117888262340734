import { createTheme } from "@mui/material/styles";
export let theme = createTheme({});
theme = createTheme({
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => "none",
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    h4: {
      fontWeight: 600,
      fontSize: 30,
    },
    h6: {
      fontWeight: 600,
      fontSize: 20,
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1400 !important", // Your custom value here
          },
          // Note that you can customize other properties here, like padding, color, .etc.
        },
        maxWidthXl: {
          [theme.breakpoints.up("xl")]: {
            maxWidth: "1800px !important",
            // zoom: "1.1",
            // Your custom value here
          },
          [theme.breakpoints.up("1920")]: {
            maxWidth: "2000px !important", // Your custom value here
            // zoom: "1.25",
          },
          [theme.breakpoints.up("2200")]: {
            maxWidth: "2100px !important", // Your custom value here
          },
          [theme.breakpoints.up("2500")]: {
            maxWidth: "2400px !important", // Your custom value here
          },
          // Note that you can customize other properties here, like padding, color, .etc.
        },
      },
    },
  },
});
