import React, { useState, useEffect, Fragment } from "react";
import "./Charts.css";
import { isEqual } from "lodash";
import { Line, Doughnut } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Skeleton } from "@mui/material";
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 10,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  boxStylePie: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "@media (max-width: 900px)": {
      marginTop: 40,
    },
  },
  label: {
    padding: "inherit",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    marginTop: 20,
  },
}));

export const Charts = ({ data }) => {
  const classes = useStyles();

  const [localData, setLocalData] = useState({});

  useEffect(() => {
    if (!isEqual(localData, data)) {
      setLocalData(data);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const { sortAboveTarget, sortBelowTarget } = localData && localData;

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "white";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      if (window.innerWidth < 1366) {
        tooltipEl.style.top = "50% ";
        tooltipEl.style.left = "50%";
        tooltipEl.style.transform = "translate(-50%, -50%)";
      } else {
        tooltipEl.style.transform = "translate(-50%, 0)";
      }
      tooltipEl.style.transition = "all .1s ease";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement("thead");
      tableHead.style.textAlign = "left";
      titleLines.forEach((title) => {
        // console.log(title);
        const tr = document.createElement("tr");
        tr.style.borderWidth = 0;

        const th = document.createElement("th");
        th.style.borderWidth = 0;
        // const text = document.createTextNode(title);
        let userData;
        if (tooltip.dataPoints[0].dataset.label === "Below target %") {
          // console.log(sortBelowTarget, tooltip.dataPoints[0].dataIndex)
          userData = sortBelowTarget[tooltip.dataPoints[0].dataIndex];
        } else {
          userData = sortAboveTarget[tooltip.dataPoints[0].dataIndex];
        }
        // console.log(userData)
        th.innerHTML = `<div>
        <span>
        Name: ${userData.name}
        </span>
        </br>
        <span>
        Email: ${userData.email}
        </span>
        <hr>
      </div>`;

        // th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
        // console.log(body, i);
        const colors = tooltip.labelColors[i];

        const span = document.createElement("span");
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "10px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.display = "inline-block";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = 0;

        const td = document.createElement("td");
        td.style.borderWidth = 0;

        const text = document.createTextNode(body);

        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    if (window.innerWidth > 1365) {
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
    }
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    plugins: {
      title: {
        display: true,
        text: "Top 5 Learners Below And Over Target",
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
  };

  return (
    <Fragment>
      {localData ? (
        <Box className={classes.boxStyle}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
                Main Data
              </Typography>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Grid item xs={12} md={4}>
                  {/* <div style={{ position: "relative" }}>
                    <Line
                      data={localData && localData.barChart}
                      options={options}
                    />
                  </div> */}
                  <div className={classes.boxStylePie}>
                    <Doughnut
                      data={localData && localData.doughnutChartTargetStatus}
                      options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        animation: false,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={4}>
                  <div className={classes.boxStylePie}>
                    <Doughnut
                      data={localData && localData.doughnutChartProgramStatus}
                      options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        animation: false,
                      }}
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div
          className={classes.skeleton}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 20,
          }}
        >
          <Skeleton
            variant="text"
            height={45}
            style={{ gridColumn: "1 / -1" }}
          />
          <Skeleton variant="rect" height={160} />
          <Skeleton variant="rect" height={160} />
        </div>
      )}
    </Fragment>
  );
};
