import React, { useState, useContext, Fragment } from "react";
import "./ResetPassword.css";
import CompanyListModal from "../../components/CompanyListModal/CompanyListModal";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Avatar,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactInputVerificationCode from "react-input-verification-code";
import { AppContext } from "../../context/appContext";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { validateEmail } from "../../utils/functions";

const useStyles = makeStyles(() => ({
  mainHeight: {
    height: "calc(100vh - 64px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#5335CA",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  button: {
    background: "#5335CA",
    color: "white",
    transition: "background .3s linear",
    "&:hover": {
      background: "#4b27dd",
    },
  },
}));

export const ResetPassword = () => {
  // data, setData, handleToggle, updateMessage, getUserData
  const { setData, handleToggle, updateMessage } = useContext(AppContext);

  const classes = useStyles();
  const history = useHistory();

  // States
  const [resetInfo, setResetInfo] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    code: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
  });
  const [resetStep, setResetStep] = useState(1);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companies, setCompanies] = useState("");

  // 1

  const sendVerificationCode = async (e) => {
    e.preventDefault();
    if (resetInfo.email && validateEmail(resetInfo.email)) {
      try {
        handleToggle(true);
        await axios
          .get(`general/forgot-password/${resetInfo.email}`)
          .then((response) => {
            sessionStorage.setItem("7-star-training-temp", response.data.token);
            setResetStep(resetStep + 1);
            handleToggle(false);
            updateMessage(response.data.message);
          });
      } catch (e) {
        if (e.response && e.response.data) {
          handleToggle(false);
          updateMessage(e.response.data.message);
        }
      }
    } else {
      setResetInfo({
        ...resetInfo,
        emailError: "Please enter valid email",
      });
    }
  };

  // 2

  const verifyCode = async (e) => {
    e.preventDefault();
    if (resetInfo.code.length === 4) {
      try {
        handleToggle(true);
        await axios
          .post(`general/verify`, resetInfo, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            setResetStep(resetStep + 1);
            handleToggle(false);
          });
      } catch (e) {
        if (e.response && e.response.data) {
          handleToggle(false);
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  const isValid = () => {
    let passwordError = "Please enter password";
    let confirmPasswordError = "Password does not match";
    if (resetInfo.password) {
      passwordError = "";
    }
    if (
      resetInfo.confirmPassword &&
      resetInfo.password === resetInfo.confirmPassword
    ) {
      confirmPasswordError = "";
    }
    if (passwordError || confirmPasswordError) {
      setResetInfo({
        ...resetInfo,
        passwordError,
        confirmPasswordError,
      });
      return false;
    }
    return true;
  };

  // 3

  const resetPassword = async (e) => {
    e.preventDefault();
    if (isValid()) {
      try {
        handleToggle(true);
        await axios.put(`general`, resetInfo).then(async (response) => {
          handleToggle(false);
          updateMessage(response.data.message);
          sessionStorage.setItem("7-star-training-temp", response.data.token);
          if (response.data.data.length > 1) {
            setCompanies(response.data.data);
            setShowCompanyModal(!showCompanyModal);
          } else {
            try {
              await axios
                .get(
                  `general/login/${response.data.data[0].sanitizeCompanyName}`,
                  {
                    config: { handlerEnabled: true },
                  }
                )
                .then((response) => {
                  localStorage.setItem("7-star-training", response.data.token);
                  setData(response.data.data);
                  history.push(
                    `/${response.data.data.role}/${response.data.data._id}`
                  );
                });
            } catch (e) {
              if (e.response && e.response.data) {
                updateMessage(e.response.data.message);
              }
            }
          }
        });
      } catch (e) {
        handleToggle(false);
        if (e.response && e.response.data) {
          updateMessage(e.response.data.message);
        }
      }
    }
  };

  const resetChangeHandler = (e) => {
    let { name, value } = e.target;
    if (value && resetInfo[name + "Error"]) {
      setResetInfo({
        ...resetInfo,
        [name]: value,
        [name + "Error"]: "",
      });
    } else {
      setResetInfo({
        ...resetInfo,
        [name]: value,
      });
    }
  };

  const onCodeChange = (e) => {
    setResetInfo({
      ...resetInfo,
      code: e,
    });
  };

  return (
    <Fragment>
      <div className="cosmetics">
        <Container
          component="main"
          maxWidth="xs"
          className={classes.mainHeight}
        >
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Fragment>
              {resetStep === 1 && (
                <Fragment>
                  <Typography component="h1" variant="h5">
                    Forget Password
                  </Typography>

                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={sendVerificationCode}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={resetInfo.email || ""}
                      onChange={(e) => resetChangeHandler(e)}
                      error={resetInfo.emailError === "" ? false : true}
                      helperText={resetInfo.emailError}
                    />

                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={sendVerificationCode}
                    >
                      Send Me Instructions
                    </Button>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid>
                        <Typography
                          variant="body2"
                          style={{ textDecoration: "none", marginTop: ".5rem" }}
                        >
                          <RouterLink to="/" style={{ textDecoration: "none" }}>
                            Back To Login Page
                          </RouterLink>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Fragment>
              )}
            </Fragment>
            <Fragment>
              {resetStep === 2 && (
                <Fragment>
                  <Typography component="h1" variant="h5">
                    Enter OTP Code
                  </Typography>

                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={verifyCode}
                  >
                    <Grid style={{ padding: "15px 0px" }}>
                      <ReactInputVerificationCode
                        autoFocus={true}
                        value={resetInfo.code}
                        onChange={onCodeChange}
                        placeholder=""
                      />
                    </Grid>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={verifyCode}
                    >
                      Submit
                    </Button>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid>
                        <Typography
                          variant="body2"
                          style={{ textDecoration: "none", marginTop: ".5rem" }}
                        >
                          <RouterLink to="/" style={{ textDecoration: "none" }}>
                            Back To Login Page
                          </RouterLink>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Fragment>
              )}
            </Fragment>
            <Fragment>
              {resetStep === 3 && (
                <Fragment>
                  <Typography component="h1" variant="h5">
                    Reset Password
                  </Typography>

                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={resetPassword}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      name="password"
                      autoComplete="password"
                      autoFocus
                      value={resetInfo.password}
                      type="password"
                      onChange={(e) => resetChangeHandler(e)}
                      error={resetInfo.passwordError === "" ? false : true}
                      helperText={resetInfo.passwordError}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      autoComplete="confirmPassword"
                      type="password"
                      value={resetInfo.confirmPassword}
                      onChange={(e) => resetChangeHandler(e)}
                      error={
                        resetInfo.confirmPasswordError === "" ? false : true
                      }
                      helperText={resetInfo.confirmPasswordError}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={resetPassword}
                    >
                      Save
                    </Button>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid>
                        <Typography
                          variant="body2"
                          style={{ textDecoration: "none", marginTop: ".5rem" }}
                        >
                          <RouterLink to="/" style={{ textDecoration: "none" }}>
                            Back To Login Page
                          </RouterLink>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Fragment>
              )}
            </Fragment>
          </div>
        </Container>
      </div>

      <CompanyListModal
        open={showCompanyModal}
        close={() => setShowCompanyModal(false)}
        companies={companies}
      />
    </Fragment>
  );
};
