import React, { useState, Fragment, useContext } from "react";
import "./HelpAndSupport.css";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormGroup,
  Modal,
  TextareaAutosize,
  Typography,
  Skeleton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import { AppContext } from "../../context/appContext";
import Grid from "@mui/material/Grid";
import CloseIcon from '@mui/icons-material/Close';
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  cardStyle: {
    marginTop: 20,
    borderRadius: 10,
  },
  cardStyleHelpAndSupport: {
    marginTop: 22,
    borderRadius: 10,
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  buttonn: {
    background: "#939393",
    "&:hover": {
      background: "#888888",
    },
    marginRight: 10,
  },
  textArea: {
    outline: "none",
    minWidth: 500,
    maxWidth: "100%",
    overflow: "auto !important",
    maxHeight: 300,
    padding: 10,
    resize: "none",
    marginBottom: 20,
    borderRadius: 5,
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
    cursor: "pointer",
    zIndex: 10,
  },
  skeleton: {
    backgroundColor: '#fff',
    padding: '20px',
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 10,
    marginTop: 20,
  }
}));

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "95vw",
  maxHeight: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const HelpAndSupport = (props) => {
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);

  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [supportText, setSupportText] = useState("");
  const classes = useStyles();

  // To Send Help & Support Message
  const sendSupportMessage = async () => {
    let data = {
      message: supportText,
    };
    try {
      handleToggle(true)
      await axios
        .post("faculty/help", data, {
          config: { handlerEnabled: true },
        })
        .then(() => {
          setOpenSupportModal(false);
          handleToggle(false)
          updateMessage("Help request submitted successfully.")
          setSupportText("");
        });
    } catch (e) {
      handleToggle(false)
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  return (
    <Fragment>

      <Fragment>
        {data ?
          (<Box className={classes.cardStyleHelpAndSupport}>
            <Card>
              <CardContent className="card-content">
                <Typography variant="h6" gutterBottom>
                  Help & Support
                </Typography>
                <Typography variant="body2">
                  {props.message}
                </Typography>
                <CardActions sx={{ padding: 0, paddingTop: 1.2, paddingBottom: 1.2 }}>
                  <Button
                    variant="contained"
                    endIcon={<SendIcon size="small" />}
                    className={classes.button}
                    onClick={() => setOpenSupportModal(!openSupportModal)}
                  >
                    Send a message
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Box>)
          :
          (<div className={classes.skeleton} >
            <Skeleton variant="text" height={45} />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="70%" height={45} />
          </div>)
        }
      </Fragment>

      {/* Help & Support Modal */}
      <Modal
        open={openSupportModal}
        onClose={() => setOpenSupportModal(!openSupportModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={stylee} className="modalContainer">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="modalHeader"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Send a message
            </Typography>
            <CloseIcon
              className={classes.icons}
              title="Close"
              onClick={() => setOpenSupportModal(!openSupportModal)}
            />
          </Grid>
          <FormGroup sx={{ paddingTop: 3 }}>
            <TextareaAutosize
              aria-label="helpMessage textarea"
              minRows={4}
              placeholder="Message..."
              label="Work Email"
              className={`helpTextArea ${classes.textArea}`}
              name="helpMessage"
              value={supportText}
              onChange={(e) => setSupportText(e.target.value)}
            />
            <div className="modalFooter">
              <div
                style={{
                  // paddingTop: "10px",
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={sendSupportMessage}
                  disabled={!supportText}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  className={classes.buttonn}
                  onClick={() => setOpenSupportModal(!openSupportModal)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </FormGroup>
        </Box>
      </Modal>
    </Fragment >
  );
};

export default HelpAndSupport;
