import * as React from "react";
import { useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreActions from "./MoreActions";
import { capitalCase } from "capital-case";
import Toolbar from "./Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { getAllFaculty } from "../../redux/slices/faculty";
import { useParams } from "react-router-dom";
import { Card } from "@mui/material";

export default function FacultyListNew({ deleteFaculty, edit }) {
  const pageRole = window.location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const { faculties } = useSelector((state) => state.faculty);

  return (
    <Card sx={{ mt: 2 }}>
      {/* {pageRole === "admin" && <Toolbar />} */}
      {<Toolbar />}
      <TableContainer
        component={Paper}
        sx={{
          mt: pageRole === "admin" ? 2 : 0,
          maxHeight: 386,
          minHeight: 386,
        }}
      >
        <Table 
        sx={{ 
          // maxWidth: 960, 
        }} 
        stickyHeader 
        aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              {pageRole === "admin" && <TableCell align="center">Role</TableCell>}
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faculties.length > 0 &&
              faculties.map((row) => {
                const { _id, name, email, attribute } = row;
                return (
                  <TableRow
                    key={_id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& td, & th": {
                        maxWidth: "90px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {name}
                    </TableCell>
                    <TableCell align="left">{email}</TableCell>
                    {pageRole === "admin" && (
                      <TableCell align="center">
                        {capitalCase(attribute, "PascalCase")}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <MoreActions
                        faculty={row}
                        deleteFaculty={deleteFaculty}
                        edit={edit}
                      ></MoreActions>
                    </TableCell>
                  </TableRow>
                );
              })}
            {faculties.length <= 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={6}
                  sx={{
                    padding: "8.075rem 0",
                    "&.MuiTableCell-root": { border: 0 },
                  }}
                >
                  No matching results are found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
